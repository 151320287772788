import React from "react"
import { DashboardScreen_PricingFragment } from "~/__generated__/graphql"
import { formatCurrency } from "~/common/format-currency"
import { platformLogoMap } from "~/common/platformLogos"
import Text from "~/ui/typography"
import { DashboardCard } from "./DashboardCard"

type PlatformStatsProps = {
  platform: string
  stats: Array<{
    label: string
    stats: DashboardScreen_PricingFragment
  }>
}

export const PlatformPricing: React.FC<PlatformStatsProps> = ({
  platform,
  stats,
}) => {
  const image = platformLogoMap[platform]
  return (
    <DashboardCard
      title={
        <div className="flex items-center gap-2">
          {image ? <img {...image} alt="" className="flex" /> : null}
          <Text variant="body2">{platform} Pricing</Text>
        </div>
      }
    >
      <div className="flex flex-col gap-2 text-body4 text-gray-1e">
        {stats.map(({ label, stats }) => {
          let { average } = stats
          return (
            <div key={label} className="flex flex-row gap-2">
              <div className="flex-1">
                <Text variant="body4">{label}</Text>
              </div>
              <div className="flex-1 text-right">
                <div className="text-greenDark">{formatCurrency(average)}</div>
              </div>
            </div>
          )
        })}
      </div>
    </DashboardCard>
  )
}
