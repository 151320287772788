import { useFormContext, useWatch } from "react-hook-form"
import { audienceGenderOptions } from "~/common/enums"
import { Checkbox } from "~/shadcn/ui/checkbox"
import { FormField, FormItem, FormMessage } from "~/shadcn/ui/form"
import { SearchSubLabel } from "./SearchSubLabel"
import { SearchValues } from "../../schema"
import { AudienceGenderEnum } from "~/__generated__/graphql"

const FIELD_NAME = "audienceGenders"

export const AudienceGenderFilter = () => {
  const form = useFormContext<SearchValues>()

  const selectedGenders =
    useWatch({
      control: form.control,
      name: FIELD_NAME,
    }) || []

  const toggleGender = (gender: AudienceGenderEnum) => {
    if (selectedGenders.includes(gender)) {
      form.setValue(
        FIELD_NAME,
        selectedGenders.filter((c) => c !== gender)
      )
    } else {
      form.setValue(FIELD_NAME, [...selectedGenders, gender])
    }
  }

  return (
    <FormField
      control={form.control}
      name={FIELD_NAME}
      render={() => (
        <FormItem className="flex-1">
          <SearchSubLabel text="Audience Demographic" />
          <div className="grid grid-cols-2 gap-2">
            {audienceGenderOptions.map((option) => (
              <GenderOption
                key={option.value}
                label={option.label}
                value={option.value}
                selected={selectedGenders.includes(option.value)}
                onClick={() => toggleGender(option.value)}
              />
            ))}
          </div>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

const GenderOption = ({
  label,
  value,
  selected,
  onClick,
}: {
  label: string
  value: AudienceGenderEnum
  selected: boolean
  onClick: () => void
}) => {
  return (
    <div className="flex items-center space-x-2">
      <Checkbox
        id={`audience-gender-${value}`}
        checked={selected}
        onCheckedChange={onClick}
      />
      <label
        htmlFor={`audience-gender-${value}`}
        className="leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
      >
        {label}
      </label>
    </div>
  )
}
