// use static-path to generate type-safe paths
// https://github.com/garybernhardt/static-path

import { path } from "static-path"

export const loadingPath = path("/")
export const dashboardPath = path("/dashboard")
export const rosterPath = path("/roster")
export const agenciesPath = path("/agencies")
export const myRosterPath = path("/my-roster")
export const settingsAccountPath = path("/settings/account")

// admin
export const adminUsersPath = path("/admin/users")

// onboarding
export const signInPath = path("/sign-in")
export const signInTokenPath = path("/users/sign_in/:sessionId")
export const signUpPath = path("/sign-up/:invitationToken")
export const forgotPasswordPath = path("/forgot-password")
export const resetPasswordPath = path("/reset-password/:resetToken")
export const onboardingPersonalDetailsPath = path(
  "/onboarding/personal-details"
)
export const onboardingAgencyDetailsPath = path("/onboarding/agency-details")
export const onboardingYourRosterPath = path("/onboarding/roster")
export const accountInactivePath = path("/account-inactive")

export const afterLoginPath = dashboardPath

// server routes
export const serverSignInPath = path("/sessions/create")
export const serverSignOutPath = path("/sign-out")
export const goodJobPath = path("/good_job")
