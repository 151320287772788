"use client"

import {
  Dialog,
  DialogBackdrop,
  DialogTitle,
  DialogPanel,
} from "@headlessui/react"
import { type ClassValue } from "clsx"
import { cn } from "~/common/shadcn-utils"
import { Hr } from "./Hr"

type ModalSize = "sm" | "lg"

export const ModalDialog = ({
  open,
  onClose,
  children,
  className,
  size = "lg",
}: {
  open: boolean
  onClose: () => void
  children: React.ReactNode
  className?: ClassValue
  size?: ModalSize
}) => {
  return (
    <Dialog open={open} onClose={onClose} className="relative z-40">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray50 bg-opacity-50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className={cn(
              "relative transform overflow-auto rounded-lg border border-gray30 bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95",
              { "sm:max-w-lg": size === "sm" },
              {
                "sm:h-[90vh] flex-1 flex flex-col sm:max-w-[1500px] mx-8":
                  size === "lg",
              },
              className
            )}
          >
            {children}
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  )
}

export const ModalDialogHeader = ({
  title,
  subtitle,
}: {
  title: string
  subtitle?: string
}) => {
  return (
    <div className="mb-4">
      <DialogTitle
        as="h3"
        className="font-bold font-header text-xl leading-6 mb-2"
      >
        {title}
      </DialogTitle>
      {subtitle && (
        <DialogTitle className="my-2 text-gray50">{subtitle}</DialogTitle>
      )}
      <Hr className="mt-4" />
    </div>
  )
}
