import { useFormContext, useWatch } from "react-hook-form"
import { collaborationTypeOptions } from "~/common/enums"
import { Checkbox } from "~/shadcn/ui/checkbox"
import { FormField, FormItem, FormMessage } from "~/shadcn/ui/form"
import { SearchLabel } from "./SearchLabel"
import { SearchValues } from "../../schema"
import { CollaborationTypeEnum } from "~/__generated__/graphql"

const FIELD_NAME = "collaborationTypes"

export const CollaborationTypeFilter = () => {
  const form = useFormContext<SearchValues>()

  const selectedCollaborationTypes =
    useWatch({
      control: form.control,
      name: FIELD_NAME,
    }) || []

  const toggleCollaborationType = (
    collaborationType: CollaborationTypeEnum
  ) => {
    if (selectedCollaborationTypes.includes(collaborationType)) {
      form.setValue(
        FIELD_NAME,
        selectedCollaborationTypes.filter((c) => c !== collaborationType)
      )
    } else {
      form.setValue(FIELD_NAME, [
        ...selectedCollaborationTypes,
        collaborationType,
      ])
    }
  }

  return (
    <FormField
      control={form.control}
      name={FIELD_NAME}
      render={() => (
        <FormItem>
          <SearchLabel text="Type of Collaboration" />
          <div className="grid grid-cols-4 gap-2">
            {collaborationTypeOptions.map((option) => (
              <CollaborationTypeOption
                key={option.value}
                label={option.label}
                value={option.value}
                selected={selectedCollaborationTypes.includes(option.value)}
                onClick={() => toggleCollaborationType(option.value)}
              />
            ))}
          </div>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

const CollaborationTypeOption = ({
  label,
  value,
  selected,
  onClick,
}: {
  label: string
  value: CollaborationTypeEnum
  selected: boolean
  onClick: () => void
}) => {
  const id = `collaboration-type-${value}`

  return (
    <div className="flex items-center space-x-2">
      <Checkbox id={id} checked={selected} onCheckedChange={onClick} />
      <label
        htmlFor={id}
        className="leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
      >
        {label}
      </label>
    </div>
  )
}
