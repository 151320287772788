import { getFragmentData } from "~/__generated__"
import { FullInfluencerFragment } from "~/__generated__/graphql"
import { HelpPopover } from "~/ui/HelpPopover"
import { numberDisplay, percentDisplay, pricingDisplay } from "~/util/numbers"
import { IconHeading } from "../../components/IconHeading"
import { Label } from "./Label"
import { LabelHeader } from "./LabelHeader"
import { SOOCIAL_MEDIA_FEED_PRICING_FRAGMENT } from "../../api"

import youtubeIcon from "~/images/icons/youtube.png"

export const YoutubeInfo = ({
  influencer,
}: {
  influencer: FullInfluencerFragment
}) => {
  if (!influencer.youtubeProfile) {
    return null
  }

  return (
    <>
      <IconHeading
        title="Youtube"
        rightSide={
          <a
            href={influencer.youtubeProfile.url || ""}
            target="_blank"
            rel="noreferrer"
          >
            {influencer.youtubeProfile.handle}
          </a>
        }
        icon={<img src={youtubeIcon} alt="Youtube" />}
        className="mb-4"
      />
      <LabelHeader>Analytics</LabelHeader>
      <div className="grid grid-cols-4 gap-8 mb-6">
        <div>
          <Label>Subscribers</Label>
          <div>{numberDisplay(influencer.youtubeProfile.subscribers)}</div>
        </div>
        <div>
          <Label>Videos: AVG Likes</Label>
          <div>
            {numberDisplay(influencer.youtubeProfile.averageVideoLikes)}
          </div>
        </div>
        <div>
          <Label>Videos: AVG Views</Label>
          <div>
            {numberDisplay(influencer.youtubeProfile.averageVideoViews)}
          </div>
        </div>
        <div>
          <div className="flex flex-row gap-2">
            <Label>Videos: ENG Rate</Label>
            <HelpPopover helpText="AVG Likes / AVG Views" />
          </div>
          <div>
            {percentDisplay(influencer.youtubeProfile.videoEngagementRate)}
          </div>
        </div>
        <div>
          <Label>Shorts: AVG Likes</Label>
          <div>
            {numberDisplay(influencer.youtubeProfile.averageShortsLikes)}
          </div>
        </div>
        <div>
          <Label>Shorts: AVG Views</Label>
          <div>
            {numberDisplay(influencer.youtubeProfile.averageShortsViews)}
          </div>
        </div>
        <div>
          <div className="flex flex-row gap-2">
            <Label>Shorts: ENG Rate</Label>
            <HelpPopover helpText="AVG Likes / AVG Views" />
          </div>
          <div>
            {percentDisplay(influencer.youtubeProfile.shortsEngagementRate)}
          </div>
        </div>
      </div>
      <LabelHeader>Pricing</LabelHeader>
      <div className="flex flex-row justify-stretch gap-8">
        <div className="flex-1">
          <Label>Integration</Label>
          <div>
            {pricingDisplay(
              getFragmentData(
                SOOCIAL_MEDIA_FEED_PRICING_FRAGMENT,
                influencer.youtubeProfile.integrationPricing
              )
            )}
          </div>
        </div>
        <div className="flex-1">
          <Label>Dedication</Label>
          <div>
            {pricingDisplay(
              getFragmentData(
                SOOCIAL_MEDIA_FEED_PRICING_FRAGMENT,
                influencer.youtubeProfile.dedicationPricing
              )
            )}
          </div>
        </div>
        <div className="flex-1">
          <Label>Shorts</Label>
          <div>
            {pricingDisplay(
              getFragmentData(
                SOOCIAL_MEDIA_FEED_PRICING_FRAGMENT,
                influencer.youtubeProfile.shortsPricing
              )
            )}
          </div>
        </div>
        <div className="flex-1"></div>
      </div>
      {influencer.youtubeProfile.notes && (
        <div className="bg-gray10 p-4 mt-4">
          <Label>Notes</Label>
          {influencer.youtubeProfile.notes}
        </div>
      )}
    </>
  )
}
