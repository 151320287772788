import Text from "~/ui/typography"

export const HelpText = () => {
  return (
    <Text variant="body3" className="block mt-3 text-gray80">
      <b>Feeling overwhelmed?</b> Select ‘Skip for now’ and email us your roster
      in any format (excel, link or PDF) to{" "}
      <a
        href="mailto:support@zanoplatform.com"
        className="text-blueDark underline-offset-4 hover:underline dark:text-slate-50"
      >
        support@zanoplatform.com
      </a>
      . We’ll handle the upload for you!
    </Text>
  )
}
