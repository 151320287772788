import { useEffect } from "react"
import { Link } from "react-router-dom"
import { AgencySortFieldEnum } from "~/__generated__/graphql"
import { getFragmentData } from "~/__generated__"
import { useHeaderContext } from "~/common/HeaderContext"
import { rosterPath } from "~/common/paths"
import { Button } from "~/shadcn/ui/button"
import { Card, CardContent } from "~/shadcn/ui/card"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/shadcn/ui/table"
import { Avatar } from "~/ui/Avatar"
import { CopyButton } from "~/ui/CopyButton"
import { InfiniteLoadMore } from "~/ui/InfiniteLoadMore"
import { friendlyNumber, numberDisplay } from "~/util/numbers"
import { useAgencies } from "./useAgencies"
import { AgencySortProvider } from "./components/AgencySortContext"
import { SortableColumn } from "./components/SortableColumn"
import { AGENCY_FRAGMENT } from "./api"

import instagramIcon from "~/images/icons/instagram.png"
import tikTokIcon from "~/images/icons/tiktok.png"
import twitchIcon from "~/images/icons/twitch.png"
import youtubeIcon from "~/images/icons/youtube.png"
import { businessTypeName } from "~/common/enums"

export const AgenciesScreen = () => {
  const { setHeaderText, setSubheaderText } = useHeaderContext()
  const {
    agencies,
    data,
    loading,
    sortField,
    sortDir,
    onSort,
    onLoadNextPage,
    pageInfo,
  } = useAgencies()

  useEffect(() => {
    setHeaderText("Agencies")
  }, [setHeaderText])

  useEffect(() => {
    setSubheaderText(
      loading
        ? "Loading..."
        : `${numberDisplay(data?.agencies.totalCount)} Agencies`
    )
  }, [setSubheaderText, loading, data?.agencies.totalCount])

  return (
    <div className="h-full pb-4 relative">
      <Card className="absolute left-0 right-0 top-0 bottom-4 overflow-auto">
        <CardContent className="flex-1 p-6 pt-4 flex flex-col">
          <Card className="absolute top-4 bottom-4 left-6 right-6 flex-grow overflow-auto">
            <Table>
              <TableHeader>
                <AgencySortProvider
                  sortField={sortField}
                  sortDir={sortDir}
                  onSort={onSort}
                >
                  <TableRow>
                    <TableHead sticky>
                      <SortableColumn field={AgencySortFieldEnum.Name}>
                        Name
                      </SortableColumn>
                    </TableHead>
                    <TableHead sticky>
                      <SortableColumn field={AgencySortFieldEnum.BusinessType}>
                        Business Type
                      </SortableColumn>
                    </TableHead>
                    <TableHead sticky>
                      <SortableColumn
                        field={AgencySortFieldEnum.InfluencerCount}
                      >
                        Influencers
                      </SortableColumn>
                    </TableHead>
                    <TableHead sticky>
                      <SortableColumn
                        field={AgencySortFieldEnum.TiktokFollowers}
                      >
                        <div className="flex gap-1 items-center">
                          <img
                            src={tikTokIcon}
                            alt="TikTok"
                            className="w-[18px] h-[20px]"
                          />
                          TT
                        </div>
                      </SortableColumn>
                    </TableHead>
                    <TableHead sticky>
                      <SortableColumn
                        field={AgencySortFieldEnum.InstagramFollowers}
                      >
                        <div className="flex gap-1 items-center">
                          <img
                            src={instagramIcon}
                            alt="Instagram"
                            className="w-[18px] h-[18px]"
                          />
                          IG
                        </div>
                      </SortableColumn>
                    </TableHead>
                    <TableHead sticky>
                      <SortableColumn
                        field={AgencySortFieldEnum.YoutubeSubscribers}
                      >
                        <div className="flex gap-1 items-center">
                          <img
                            src={youtubeIcon}
                            alt="TikTok"
                            className="w-[19px] h-[13px]"
                          />
                          YT
                        </div>
                      </SortableColumn>
                    </TableHead>
                    <TableHead sticky>
                      <SortableColumn
                        field={AgencySortFieldEnum.TwitchSubscribers}
                      >
                        <div className="flex gap-1 items-center">
                          <img
                            src={twitchIcon}
                            alt="TikTok"
                            className="w-[17px] h-[18px]"
                          />
                          TW
                        </div>
                      </SortableColumn>
                    </TableHead>
                    <TableHead sticky>
                      <SortableColumn field={AgencySortFieldEnum.ContactEmail}>
                        Email
                      </SortableColumn>
                    </TableHead>
                    <TableHead sticky />
                  </TableRow>
                </AgencySortProvider>
              </TableHeader>
              <TableBody>
                {agencies.map((agencyF) => {
                  let agency = getFragmentData(AGENCY_FRAGMENT, agencyF)
                  return (
                    <TableRow key={agency.id}>
                      <TableCell>
                        <div className="flex items-center gap-2">
                          <Avatar
                            src={agency.logoThumbnailUrl}
                            alt={agency.name || "avatar"}
                          />
                          {agency.name}
                        </div>
                      </TableCell>
                      <TableCell>
                        {businessTypeName(agency.businessType)}
                      </TableCell>
                      <TableCell>
                        {numberDisplay(Number(agency.influencerCount))}
                      </TableCell>
                      <TableCell>
                        {friendlyNumber(Number(agency.tiktokFollowers), 0)}
                      </TableCell>
                      <TableCell>
                        {friendlyNumber(Number(agency.instagramFollowers), 0)}
                      </TableCell>
                      <TableCell>
                        {friendlyNumber(Number(agency.youtubeSubscribers), 0)}
                      </TableCell>
                      <TableCell>
                        {friendlyNumber(Number(agency.twitchSubscribers), 0)}
                      </TableCell>
                      <TableCell>
                        {agency.contactEmail && (
                          <CopyButton
                            textToCopy={agency.contactEmail}
                            successMessage={`"${agency.contactEmail}" copied to clipboard`}
                            label={agency.contactEmail}
                            className="w-full justify-between"
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        {agency.influencerCount > 0 && (
                          <Link to={`${rosterPath({})}?agency=${agency.id}`}>
                            <Button variant="outline" size="sm">
                              View Roster
                            </Button>
                          </Link>
                        )}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
            <InfiniteLoadMore
              onEndReached={onLoadNextPage}
              canLoadMore={!loading && !!pageInfo?.hasNextPage}
              loading={loading && agencies.length > 0}
              className="p-4"
            />
            {loading && <div>Loading...</div>}
          </Card>
        </CardContent>
      </Card>
    </div>
  )
}
