import { FullInfluencerFragment } from "~/__generated__/graphql"
import { ageRangeName, audienceGenderName } from "~/common/enums"
import { IconHeading } from "../../components/IconHeading"
import { Label } from "./Label"
import { TikTokInfo } from "./TikTokInfo"
import { InstagramInfo } from "./InstagramInfo"
import { YoutubeInfo } from "./YoutubeInfo"
import { TwitchInfo } from "./TwitchInfo"

import TargetIcon from "~/images/icons/target.svg?react"
import TagIcon from "~/images/icons/tag.svg?react"
import { Tag } from "~/ui/Tag"

export const SocialMediaTab = ({
  influencer,
}: {
  influencer: FullInfluencerFragment
}) => {
  return (
    <div>
      <IconHeading icon={<TagIcon />} title="Tags" className="mb-4" />
      <div className="flex flex-wrap gap-2">
        {(influencer.tags || []).map((tag) => (
          <Tag key={tag.id} text={tag.name} />
        ))}
      </div>

      <TikTokInfo influencer={influencer} />
      <InstagramInfo influencer={influencer} />
      <YoutubeInfo influencer={influencer} />
      <TwitchInfo influencer={influencer} />

      <IconHeading
        icon={<TargetIcon />}
        title="Audience Demographics"
        className="mb-4"
        helpText="We are working on getting live data with percentages for audience demographics for each platform! Until it’s ready, here’s the majority this influencer has across all channels."
      />
      <div className="flex flex-row justify-stretch gap-8">
        <div className="flex-1">
          <Label>Country</Label>
          <div>{influencer.audienceCountryData?.name}</div>
        </div>
        <div className="flex-1">
          <Label>Age</Label>
          <div>{ageRangeName(influencer.audienceAge)}</div>
        </div>
        <div className="flex-1">
          <Label>Gender</Label>
          <div>{audienceGenderName(influencer.audienceGender)}</div>
        </div>
        <div className="flex-1"></div>
      </div>
    </div>
  )
}
