import { useState } from "react"
import { Button } from "~/shadcn/ui/button"
import { NewInfluencerScreen } from "~/screens/influencers/NewInfluencerScreen"
import { Card, CardContent, CardHeader, CardTitle } from "~/shadcn/ui/card"
import { ModalDialog } from "~/ui/ModalDialog"
import { trackEvent } from "~/util/tracking"

export const NoAccessCard = () => {
  const [openModal, setOpenModal] = useState(false)

  return (
    <Card className="absolute top-56 left-1/2 transform -translate-x-1/2 bg-white z-10 p-10 w-[700px] text-center drop-shadow-lg">
      <CardHeader>
        <CardTitle className="text-2xl font-bold">You're so close!</CardTitle>
      </CardHeader>
      <CardContent>
        <p className="mb-6">
          To access the roster database, just add at least one influencer to
          your roster. The only required fields are the influencer's name,
          exclusivity status, and at least one social profile link. (Of course,
          the more info you add, the more searchable your influencer will be!)
        </p>
        <Button
          className="mb-6"
          onClick={() => {
            setOpenModal(true)
            trackEvent("new_influencer", {})
          }}
        >
          Add an influencer
        </Button>
        <p>
          Feeling overwhelmed? No worries! Send your roster to{" "}
          <a
            href="mailto:support@zanoplatform.com"
            className="text-blueDark underline-offset-4 hover:underline dark:text-slate-50"
          >
            support@zanoplatform.com
          </a>
          , and we’ll gladly upload it for you.
        </p>
      </CardContent>
      <ModalDialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        className="sm:p-0"
      >
        <NewInfluencerScreen
          onClose={(currentTab, resetDirtyState) => setOpenModal(false)}
          onChangeDirty={() => {}}
        />
      </ModalDialog>
    </Card>
  )
}
