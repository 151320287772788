import { zodResolver } from "@hookform/resolvers/zod"
import z from "zod"
import { getFragmentData } from "~/__generated__"
import {
  AgeRangeEnum,
  AudienceGenderEnum,
  CategoryEnum,
  CollaborationTypeEnum,
  CreatorTypeEnum,
  ExclusivityEnum,
  FullInfluencerFragment,
  GenderEnum,
  InfluencerCreateInput,
  LanguageEnum,
  SocialMediaFeedPricingFragment,
  SocialMediaRateTypeEnum,
} from "~/__generated__/graphql"
import { inputToInt } from "~/util/numbers"
import { SOOCIAL_MEDIA_FEED_PRICING_FRAGMENT } from "./api"

const pricingSchema = z
  .object({
    rateType: z
      .string()
      .optional()
      .refine(
        (value) =>
          !value ||
          Object.values(SocialMediaRateTypeEnum as any).includes(value),
        "Please select a valid rate type"
      ),
    rateFlatCents: z
      .union([z.number().int().positive(), z.literal("")])
      .optional(),
    rateMinCents: z
      .union([z.number().int().positive(), z.literal("")])
      .optional(),
    rateMaxCents: z
      .union([z.number().int().positive(), z.literal("")])
      .optional(),
  })
  .refine(
    (data) => {
      if (
        data.rateType === SocialMediaRateTypeEnum.Flat &&
        data.rateFlatCents?.toString().trim() === ""
      ) {
        return false
      }
      return true
    },
    { message: "Flat Rate is required", path: ["rateFlatCents"] }
  )
  .refine(
    (data) => {
      if (
        data.rateType === SocialMediaRateTypeEnum.Range &&
        data.rateMinCents?.toString().trim() === ""
      ) {
        return false
      }
      return true
    },
    { message: "Min Rate is required", path: ["rateMinCents"] }
  )
  .refine(
    (data) => {
      if (
        data.rateType === SocialMediaRateTypeEnum.Range &&
        data.rateMaxCents?.toString().trim() === ""
      ) {
        return false
      }
      return true
    },
    { message: "Max Rate is required", path: ["rateMaxCents"] }
  )
  .refine(
    (data) => {
      if (
        data.rateType === SocialMediaRateTypeEnum.Range &&
        data.rateMinCents?.toString().trim() &&
        data.rateMaxCents?.toString().trim() &&
        parseInt(data.rateMinCents?.toString(), 10) >
          parseInt(data.rateMaxCents?.toString(), 10)
      ) {
        return false
      }
      return true
    },
    {
      message: "Max Rate must be greater than Min Rate",
      path: ["rateMaxCents"],
    }
  )

const influencerFormSchema = z.object({
  name: z.string().trim().min(1, "Full Name is required"),
  photo: z.union([z.literal(undefined), z.literal(null), z.string()]),
  age: z.union([z.number().positive().int(), z.literal("")]),
  gender: z
    .string()
    .optional()
    .refine(
      (value) => !value || Object.values(GenderEnum as any).includes(value),
      "Please select a valid gender"
    ),
  languages: z.array(z.string().trim()),

  countryCode: z.string().trim(),
  state: z.string().trim(),
  city: z.string().trim(),

  exclusivity: z
    .string()
    .refine(
      (value) => Object.values(ExclusivityEnum as any).includes(value),
      "Exclusivity is required"
    ),
  agentName: z.string().trim(),
  agentEmail: z.union([z.literal(""), z.string().trim().email()]),
  agentPhone: z.string().trim(),

  category: z
    .string()
    .optional()
    .refine(
      (value) => !value || Object.values(CategoryEnum as any).includes(value),
      "Please select a valid category"
    ),
  creatorTypes: z.array(z.string().trim()),
  collaborationTypes: z.array(z.string().trim()),
  tagIds: z.array(z.string().trim()),

  audienceCountryCode: z.string().trim(),
  audienceAge: z
    .string()
    .refine(
      (value) => !value || Object.values(AgeRangeEnum as any).includes(value),
      "Please select a valid age range"
    ),
  audienceGender: z
    .string()
    .refine(
      (value) =>
        !value || Object.values(AudienceGenderEnum as any).includes(value),
      "Please select a valid gender"
    ),

  notes: z.string().trim(),

  tikTokProfile: z
    .object({
      active: z.boolean(),
      url: z.union([z.string().trim().url(), z.literal("")]),
      notes: z.string().trim().optional(),
      videoPricing: pricingSchema.optional(),
      linkInBioPricing: pricingSchema.optional(),
    })
    .refine(
      (data) => {
        if (data.active && (!data.url || data.url.trim() === "")) {
          return false
        }
        return true
      },
      { message: "Link is required", path: ["url"] }
    )
    .optional(),
  instagramProfile: z
    .object({
      active: z.boolean(),
      url: z.union([z.string().trim().url(), z.literal("")]),
      notes: z.string().trim().optional(),
      photoPricing: pricingSchema.optional(),
      reelsPricing: pricingSchema.optional(),
      storyPricing: pricingSchema.optional(),
      linkInBioPricing: pricingSchema.optional(),
    })
    .refine(
      (data) => {
        if (data.active && (!data.url || data.url.trim() === "")) {
          return false
        }
        return true
      },
      { message: "Link is required", path: ["url"] }
    )
    .optional(),
  youtubeProfile: z
    .object({
      active: z.boolean(),
      url: z.union([z.string().trim().url(), z.literal("")]),
      notes: z.string().trim().optional(),
      integrationPricing: pricingSchema.optional(),
      dedicationPricing: pricingSchema.optional(),
      shortsPricing: pricingSchema.optional(),
    })
    .refine(
      (data) => {
        if (data.active && (!data.url || data.url.trim() === "")) {
          return false
        }
        return true
      },
      { message: "Link is required", path: ["url"] }
    )
    .optional(),
  twitchProfile: z
    .object({
      active: z.boolean(),
      url: z.union([z.string().trim().url(), z.literal("")]),
      concurrentViewers: z
        .union([z.number().positive().int(), z.literal("")])
        .optional(),
      notes: z.string().trim().optional(),
      streamPricing: pricingSchema.optional(),
    })
    .refine(
      (data) => {
        if (data.active && (!data.url || data.url.trim() === "")) {
          return false
        }
        return true
      },
      { message: "Link is required", path: ["url"] }
    )
    .optional(),
})

export type InfluencerFormValues = z.infer<typeof influencerFormSchema>
export const influencerFormResolver = zodResolver(influencerFormSchema)

const defaultSocialMediaPricingValues = (
  pricing?: SocialMediaFeedPricingFragment
): {
  rateType: SocialMediaRateTypeEnum | ""
  rateFlatCents: number | ""
  rateMinCents: number | ""
  rateMaxCents: number | ""
} => {
  return {
    rateType: pricing?.rateType || "",
    rateFlatCents: pricing?.rateFlatCents || "",
    rateMinCents: pricing?.rateMinCents || "",
    rateMaxCents: pricing?.rateMaxCents || "",
  }
}

export const defaultValues = (
  influencer?: FullInfluencerFragment
): InfluencerFormValues => {
  return {
    name: influencer?.name || "",
    photo: undefined,
    age: influencer?.age || "",
    gender: influencer?.gender || "",
    languages: influencer?.languages || [],
    countryCode: influencer?.countryCode || "",
    state: influencer?.state || "",
    city: influencer?.city || "",
    exclusivity: influencer?.exclusivity || "",
    agentName: influencer?.agentName || "",
    agentEmail: influencer?.agentEmail || "",
    agentPhone: influencer?.agentPhone || "",
    category: influencer?.category || "",
    creatorTypes: influencer?.creatorTypes || [],
    collaborationTypes: influencer?.collaborationTypes || [],
    tagIds: (influencer?.tags || []).map((tag) => tag.id),
    audienceCountryCode: influencer?.audienceCountryCode || "",
    audienceAge: influencer?.audienceAge || "",
    audienceGender: influencer?.audienceGender || "",
    notes: influencer?.notes || "",
    tikTokProfile: {
      active: influencer ? influencer?.tikTokProfile?.active || false : true,
      url: influencer?.tikTokProfile?.url || "",
      notes: influencer?.tikTokProfile?.notes || "",
      videoPricing: defaultSocialMediaPricingValues(
        influencer?.tikTokProfile?.videoPricing
          ? getFragmentData(
              SOOCIAL_MEDIA_FEED_PRICING_FRAGMENT,
              influencer?.tikTokProfile?.videoPricing
            )
          : undefined
      ),
      linkInBioPricing: defaultSocialMediaPricingValues(
        influencer?.tikTokProfile?.linkInBioPricing
          ? getFragmentData(
              SOOCIAL_MEDIA_FEED_PRICING_FRAGMENT,
              influencer?.tikTokProfile?.linkInBioPricing
            )
          : undefined
      ),
    },
    instagramProfile: {
      active: influencer?.instagramProfile?.active || false,
      url: influencer?.instagramProfile?.url || "",
      notes: influencer?.instagramProfile?.notes || "",
      photoPricing: defaultSocialMediaPricingValues(
        influencer?.instagramProfile?.photoPricing
          ? getFragmentData(
              SOOCIAL_MEDIA_FEED_PRICING_FRAGMENT,
              influencer?.instagramProfile?.photoPricing
            )
          : undefined
      ),
      reelsPricing: defaultSocialMediaPricingValues(
        influencer?.instagramProfile?.reelsPricing
          ? getFragmentData(
              SOOCIAL_MEDIA_FEED_PRICING_FRAGMENT,
              influencer?.instagramProfile?.reelsPricing
            )
          : undefined
      ),
      storyPricing: defaultSocialMediaPricingValues(
        influencer?.instagramProfile?.storyPricing
          ? getFragmentData(
              SOOCIAL_MEDIA_FEED_PRICING_FRAGMENT,
              influencer?.instagramProfile?.storyPricing
            )
          : undefined
      ),
      linkInBioPricing: defaultSocialMediaPricingValues(
        influencer?.instagramProfile?.linkInBioPricing
          ? getFragmentData(
              SOOCIAL_MEDIA_FEED_PRICING_FRAGMENT,
              influencer?.instagramProfile?.linkInBioPricing
            )
          : undefined
      ),
    },
    youtubeProfile: {
      active: influencer?.youtubeProfile?.active || false,
      url: influencer?.youtubeProfile?.url || "",
      notes: influencer?.youtubeProfile?.notes || "",
      integrationPricing: defaultSocialMediaPricingValues(
        influencer?.youtubeProfile?.integrationPricing
          ? getFragmentData(
              SOOCIAL_MEDIA_FEED_PRICING_FRAGMENT,
              influencer?.youtubeProfile?.integrationPricing
            )
          : undefined
      ),
      dedicationPricing: defaultSocialMediaPricingValues(
        influencer?.youtubeProfile?.dedicationPricing
          ? getFragmentData(
              SOOCIAL_MEDIA_FEED_PRICING_FRAGMENT,
              influencer?.youtubeProfile?.dedicationPricing
            )
          : undefined
      ),
      shortsPricing: defaultSocialMediaPricingValues(
        influencer?.youtubeProfile?.shortsPricing
          ? getFragmentData(
              SOOCIAL_MEDIA_FEED_PRICING_FRAGMENT,
              influencer?.youtubeProfile?.shortsPricing
            )
          : undefined
      ),
    },
    twitchProfile: {
      active: influencer?.twitchProfile?.active || false,
      url: influencer?.twitchProfile?.url || "",
      concurrentViewers: influencer?.twitchProfile?.concurrentViewers || "",
      notes: influencer?.twitchProfile?.notes || "",
      streamPricing: defaultSocialMediaPricingValues(
        influencer?.twitchProfile?.streamPricing
          ? getFragmentData(
              SOOCIAL_MEDIA_FEED_PRICING_FRAGMENT,
              influencer?.twitchProfile?.streamPricing
            )
          : undefined
      ),
    },
  }
}

type PricingValues = {
  rateType?: string
  rateFlatCents?: "" | number
  rateMinCents?: "" | number
  rateMaxCents?: "" | number
}
const pricingValuesToGraphqlInput = (pricingValues?: PricingValues) => {
  if (!pricingValues) {
    return null
  }

  const { rateType, rateFlatCents, rateMinCents, rateMaxCents } = pricingValues
  return {
    rateType: (rateType || null) as SocialMediaRateTypeEnum | null,
    rateFlatCents: inputToInt(rateFlatCents),
    rateMinCents: inputToInt(rateMinCents),
    rateMaxCents: inputToInt(rateMaxCents),
  }
}

export const formValuesToGraphqlInput = (
  values: InfluencerFormValues
): InfluencerCreateInput => {
  return {
    ...values,
    age: parseInt(values.age.toString()) || null,
    gender: (values.gender || null) as GenderEnum | null,
    languages: values.languages as LanguageEnum[] | null,
    exclusivity: (values.exclusivity || null) as ExclusivityEnum,
    category: (values.category || null) as CategoryEnum | null,
    collaborationTypes: values.collaborationTypes as
      | CollaborationTypeEnum[]
      | null,
    creatorTypes: values.creatorTypes as CreatorTypeEnum[] | null,
    audienceAge: (values.audienceAge || null) as AgeRangeEnum | null,
    audienceGender: (values.audienceGender ||
      null) as AudienceGenderEnum | null,
    tikTokProfile: values.tikTokProfile
      ? {
          ...values.tikTokProfile,
          videoPricing: pricingValuesToGraphqlInput(
            values.tikTokProfile?.videoPricing
          ),
          linkInBioPricing: pricingValuesToGraphqlInput(
            values.tikTokProfile.linkInBioPricing
          ),
        }
      : null,
    instagramProfile: values.instagramProfile
      ? {
          ...values.instagramProfile,
          photoPricing: pricingValuesToGraphqlInput(
            values.instagramProfile?.photoPricing
          ),
          reelsPricing: pricingValuesToGraphqlInput(
            values.instagramProfile?.reelsPricing
          ),
          storyPricing: pricingValuesToGraphqlInput(
            values.instagramProfile?.storyPricing
          ),
          linkInBioPricing: pricingValuesToGraphqlInput(
            values.instagramProfile.linkInBioPricing
          ),
        }
      : null,
    youtubeProfile: values.youtubeProfile
      ? {
          ...values.youtubeProfile,
          integrationPricing: pricingValuesToGraphqlInput(
            values.youtubeProfile?.integrationPricing
          ),
          dedicationPricing: pricingValuesToGraphqlInput(
            values.youtubeProfile?.dedicationPricing
          ),
          shortsPricing: pricingValuesToGraphqlInput(
            values.youtubeProfile.shortsPricing
          ),
        }
      : null,
    twitchProfile: values.twitchProfile
      ? {
          ...values.twitchProfile,
          concurrentViewers: inputToInt(
            values.twitchProfile?.concurrentViewers
          ),
          streamPricing: pricingValuesToGraphqlInput(
            values.twitchProfile?.streamPricing
          ),
        }
      : null,
  }
}
