import { useNavigate } from "react-router-dom"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { useHeaderContext } from "~/common/HeaderContext"
import { serverSignOutPath, settingsAccountPath } from "~/common/paths"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/shadcn/ui/dropdown-menu"
import { Avatar } from "~/ui/Avatar"
import { H1 } from "~/ui/typography"

import AccountIcon from "~/images/icons/account.svg?react"
import LogoutIcon from "~/images/icons/logout.svg?react"

export const HeadingBar = () => {
  const user = useCurrentUser()
  const { headerText, subheaderText } = useHeaderContext()
  const navigate = useNavigate()

  return (
    <div className="flex-grow flex flex-row justify-between items-center">
      <div>
        <H1>{headerText}</H1>
        {subheaderText && <p className="text-gray50">{subheaderText}</p>}
      </div>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <div className="flex flex-row items-center gap-2">
            <Avatar
              src={user.agency?.logoThumbnailUrl}
              alt={`Agency logo for ${user.agency?.name}`}
              size="md"
            />
            <div className="hidden lg:block">
              <div className="font-bold text-hoveredSecondary">
                {user.firstName} {user.lastName}
              </div>
              {user.agency && (
                <div className="text-gray80 -mt-1">{user.agency?.name}</div>
              )}
            </div>
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="min-w-[12rem]">
          <DropdownMenuItem onClick={() => navigate(settingsAccountPath({}))}>
            <AccountIcon width={17} height={17} className="mr-2" />
            Account
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => (window.location.href = serverSignOutPath({}))}
            className="text-redDark focus:text-redDark"
          >
            <LogoutIcon width={17} height={17} className="mr-2" />
            Logout
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}
