import { useMemo } from "react"
import { useFormContext, useWatch } from "react-hook-form"
import { useCountries } from "~/common/useCountries"
import { FormField, FormItem, FormMessage } from "~/shadcn/ui/form"
import { Select } from "~/ui/Select"
import { SearchSubLabel } from "./SearchSubLabel"
import { SearchValues } from "../../schema"

export const CountryFilter = ({
  name,
  label,
}: {
  name: "countryCodes" | "audienceCountryCodes"
  label: string
}) => {
  const form = useFormContext<SearchValues>()
  const { countries } = useCountries()

  const selectedCountryCodes =
    useWatch({
      control: form.control,
      name: name,
    }) || []

  const countryOptions = useMemo(() => {
    return countries.map((country) => ({
      label: `${country.emojiFlag} ${country.name}`,
      value: country.code,
    }))
  }, [countries])

  return (
    <div className="flex-1">
      <SearchSubLabel text={label} />

      <FormField
        control={form.control}
        name={name}
        render={({ field }) => (
          <FormItem>
            <Select
              options={countryOptions}
              value={countryOptions.filter((option) =>
                selectedCountryCodes.includes(option.value)
              )}
              onChange={(options) => {
                field.onChange(options.map((option) => option.value))
              }}
              isMulti
            />
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  )
}
