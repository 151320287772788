import { DashboardScreen_AgencyFragment } from "~/__generated__/graphql"
import { formatBigInt } from "~/common/format-bigint"
import { platformLogoMap } from "~/common/platformLogos"
import { Avatar } from "~/ui/Avatar"
import Text from "~/ui/typography"
import { DashboardCard } from "./DashboardCard"
import { Link } from "react-router-dom"
import { rosterPath } from "~/common/paths"

interface AgencyCardsProps {
  platform: string
  agencies: DashboardScreen_AgencyFragment[]
}

export const AgencyCards: React.FC<AgencyCardsProps> = ({
  platform,
  agencies,
}) => {
  const image = platformLogoMap[platform]
  return (
    <DashboardCard
      title={
        <div className="flex items-center gap-2">
          {image ? <img {...image} alt="" className="flex" /> : null}
          <Text variant="body2">{platform} Top Agencies</Text>
        </div>
      }
    >
      <ul className="space-y-2">
        {agencies.map((agency) => (
          <li key={agency.id}>
            <Link to={`${rosterPath({})}?agency=${agency.id}`}>
              <Text
                variant="body4"
                className="flex items-center justify-between text-gray-1e"
              >
                <div className="flex items-center space-x-2">
                  <Avatar
                    src={agency.logoThumbnailUrl}
                    alt={`Agency logo for ${agency.name}`}
                    size="sm"
                  />
                  <span className="truncate max-w-[150px]" title={agency.name}>
                    {agency.name}
                  </span>
                </div>
                <span className="text-right">
                  {platform === "TikTok" &&
                    formatBigInt(agency.tiktokFollowers)}
                  {platform === "Instagram" &&
                    formatBigInt(agency.instagramFollowers)}
                  {platform === "YouTube" &&
                    formatBigInt(agency.youtubeSubscribers)}
                  {platform === "Twitch" &&
                    formatBigInt(agency.twitchSubscribers)}
                </span>
              </Text>
            </Link>
          </li>
        ))}
      </ul>
    </DashboardCard>
  )
}
