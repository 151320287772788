import { useFormContext, useWatch } from "react-hook-form"
import { ageRangeOptions } from "~/common/enums"
import { Checkbox } from "~/shadcn/ui/checkbox"
import { FormField, FormItem, FormMessage } from "~/shadcn/ui/form"
import { SearchSubLabel } from "./SearchSubLabel"
import { SearchValues } from "../../schema"
import { AgeRangeEnum } from "~/__generated__/graphql"

const FIELD_NAME = "audienceAges"

export const AudienceAgeFilter = () => {
  const form = useFormContext<SearchValues>()

  const selectedAges =
    useWatch({
      control: form.control,
      name: FIELD_NAME,
    }) || []

  const toggleAge = (age: AgeRangeEnum) => {
    if (selectedAges.includes(age)) {
      form.setValue(
        FIELD_NAME,
        selectedAges.filter((c) => c !== age)
      )
    } else {
      form.setValue(FIELD_NAME, [...selectedAges, age])
    }
  }

  return (
    <FormField
      control={form.control}
      name={FIELD_NAME}
      render={() => (
        <FormItem className="flex-1">
          <SearchSubLabel text="Audience Demographic" />
          <div className="grid grid-cols-2 gap-2">
            {ageRangeOptions.map((option) => (
              <AgeOption
                key={option.value}
                label={option.label}
                value={option.value}
                selected={selectedAges.includes(option.value)}
                onClick={() => toggleAge(option.value)}
              />
            ))}
          </div>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

const AgeOption = ({
  label,
  value,
  selected,
  onClick,
}: {
  label: string
  value: AgeRangeEnum
  selected: boolean
  onClick: () => void
}) => {
  return (
    <div className="flex items-center space-x-2">
      <Checkbox
        id={`audience-age-${value}`}
        checked={selected}
        onCheckedChange={onClick}
      />
      <label
        htmlFor={`audience-age-${value}`}
        className="leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
      >
        {label}
      </label>
    </div>
  )
}
