import { Button } from "~/shadcn/ui/button"
import { Hr } from "~/ui/Hr"
import { ModalDialog } from "~/ui/ModalDialog"
import { H2 } from "~/ui/typography"
import { CreatorTypeFilter } from "./CreatorTypeFilter"
import { CategoryFilter } from "./CategoryFilter"
import { TagFilter } from "./TagFilter"
import { CountryFilter } from "./CountryFilter"
import { GenderFilter } from "./GenderFilter"
import { AudienceGenderFilter } from "./AudienceGenderFilter"
import { CollaborationTypeFilter } from "./CollaborationTypeFilter"
import { LanguageFilter } from "./LanguageFilter"
import { ExclusivityFilter } from "./ExclusivityFilter"
import { SearchLabel } from "./SearchLabel"
import { AgencyFilter } from "./AgencyFilter"
import { AgeFilter } from "./AgeFilter"
import { AudienceAgeFilter } from "./AudienceAgeFilter"
import { TikTokFilter } from "./TikTokFilter"
import { InstagramFilter } from "./InstagramFilter"
import { YoutubeFilter } from "./YoutubeFilter"
import { TwitchFilter } from "./TwitchFilter"

export const AllFiltersModal = ({
  open,
  setOpen,
  onClear,
  onSubmit,
  myRoster = false,
}: {
  open: boolean
  setOpen: (open: boolean) => void
  onClear: () => void
  onSubmit: () => void
  myRoster?: boolean
}) => {
  return (
    <ModalDialog open={open} onClose={() => setOpen(false)}>
      <div className="absolute top-0 bottom-0 left-0 right-0 flex flex-shrink flex-col">
        <div className="flex-grow p-6 overflow-y-auto">
          <H2>All Filters</H2>
          <CreatorTypeFilter />
          <Hr className="mt-6 mb-4" />
          <CollaborationTypeFilter />
          {!myRoster && (
            <>
              <Hr className="mt-6 mb-4" />
              <AgencyFilter />
            </>
          )}
          <Hr className="mt-6 mb-4" />
          <SearchLabel text="Platform" className="mb-0" />
          <TikTokFilter />
          <InstagramFilter />
          <YoutubeFilter />
          <TwitchFilter />
          <Hr className="mt-6 mb-4" />
          <CategoryFilter />
          <Hr className="mt-6 mb-4" />
          <TagFilter />
          <Hr className="mt-6 mb-4" />
          <SearchLabel text="Country" className="mb-0" />
          <div className="flex flex-row items-stretch gap-2">
            <CountryFilter name="countryCodes" label="Influencer" />
            <Hr variant="vertical" />
            <CountryFilter
              name="audienceCountryCodes"
              label="Audience Demographic"
            />
          </div>
          <Hr className="mt-6 mb-4" />
          <SearchLabel text="Gender" className="mb-0" />
          <div className="flex flex-row items-stretch gap-2">
            <GenderFilter />
            <Hr variant="vertical" />
            <AudienceGenderFilter />
          </div>
          <Hr className="mt-6 mb-4" />
          <SearchLabel text="Age" className="mb-0" />
          <div className="flex flex-row items-stretch gap-2">
            <AgeFilter />
            <Hr variant="vertical" />
            <AudienceAgeFilter />
          </div>
          <Hr className="mt-6 mb-4" />
          <LanguageFilter />
          <Hr className="mt-6 mb-4" />
          <ExclusivityFilter />
        </div>
        <div className="p-6 pt-0">
          <Hr />
          <div className="flex flex-row justify-between pt-3">
            <Button
              type="button"
              onClick={onClear}
              size="none"
              variant="linkSecondary"
            >
              Clear All
            </Button>
            <Button type="submit" onClick={onSubmit}>
              Apply Filters
            </Button>
          </div>
        </div>
      </div>
    </ModalDialog>
  )
}
