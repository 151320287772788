import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import invariant from "tiny-invariant"

interface HeaderContextType {
  headerText: string
  subheaderText?: string
  setHeaderText: (text: string) => void
  setSubheaderText: (text?: string) => void
}

export const HeaderContext = createContext<HeaderContextType | null>(null)

export const HeaderProvider = ({ children }: { children: ReactNode }) => {
  const [headerText, setHeaderText] = useState<string>("")
  const [subheaderText, setSubheaderText] = useState<string | undefined>("")

  const value = useMemo(
    () => ({ headerText, setHeaderText, subheaderText, setSubheaderText }),
    [headerText, setHeaderText, subheaderText, setSubheaderText]
  )

  return (
    <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>
  )
}

export const useHeaderContext = () => {
  const contextValue = useContext(HeaderContext)
  invariant(contextValue, "Context has not been Provided!")
  return contextValue
}

export const useHeaderText = ({
  header,
  subheader,
}: {
  header: string
  subheader: string | null
}) => {
  const ctx = useHeaderContext()
  useEffect(() => {
    ctx.setHeaderText(header)
    ctx.setSubheaderText(subheader ?? undefined)
  }, [ctx, header, subheader])
}
