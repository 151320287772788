import { useFormContext } from "react-hook-form"
import {
  ageRangeOptions,
  audienceGenderOptions,
  categoryOptions,
  collaborationTypeOptions,
  creatorTypeOptions,
} from "~/common/enums"
import { FormField, FormItem, FormMessage } from "~/shadcn/ui/form"
import { CountrySelect } from "~/ui/CountrySelect"
import { Select } from "~/ui/Select"
import { InfluencerFormValues } from "../schema"
import { IconHeading } from "./IconHeading"
import { InstagramProfileFields } from "./InstagramProfileFields"
import { SocialMediaToggle } from "./SocialMediaToggle"
import { TagSelect } from "./TagSelect"
import { TikTokProfileFields } from "./TikTokProfileFields"
import { TwitchProfileFields } from "./TwitchProfileFields"
import { YoutubeProfileFields } from "./YoutubeProfileFields"

import GeneralIcon from "~/images/icons/general.svg?react"
import instagramIcon from "~/images/icons/instagram.png"
import TargetIcon from "~/images/icons/target.svg?react"
import tikTokIcon from "~/images/icons/tiktok.png"
import twitchIcon from "~/images/icons/twitch.png"
import youtubeIcon from "~/images/icons/youtube.png"

export const SocialMediaFields = () => {
  const form = useFormContext<InfluencerFormValues>()

  return (
    <div>
      <IconHeading icon={<GeneralIcon />} title="General" />
      <div className="flex flex-row gap-8">
        <div className="flex-1 flex flex-col gap-4">
          <FormField
            control={form.control}
            name="category"
            render={({ field }) => (
              <FormItem>
                <Select
                  {...field}
                  value={categoryOptions.find(
                    (option) => option.value === field.value
                  )}
                  onChange={(option) => {
                    field.onChange(option?.value || "")
                  }}
                  options={categoryOptions}
                  isClearable
                  placeholder="Category"
                />
                <FormMessage />
              </FormItem>
            )}
          />
          <TagSelect />
        </div>
        <div className="flex-1 flex flex-col gap-4">
          <FormField
            control={form.control}
            name="creatorTypes"
            render={({ field }) => (
              <FormItem>
                <Select
                  {...field}
                  value={creatorTypeOptions.filter((option) =>
                    field.value.includes(option.value)
                  )}
                  onChange={(options) => {
                    field.onChange(options.map((option) => option.value))
                  }}
                  options={creatorTypeOptions}
                  isSearchable={false}
                  isClearable
                  placeholder="Creator Type(s)"
                  isMulti
                />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="collaborationTypes"
            render={({ field }) => (
              <FormItem>
                <Select
                  {...field}
                  value={collaborationTypeOptions.filter((option) =>
                    field.value.includes(option.value)
                  )}
                  onChange={(options) => {
                    field.onChange(options.map((option) => option.value))
                  }}
                  options={collaborationTypeOptions}
                  isSearchable={false}
                  isClearable
                  placeholder="Type(s) of Collaboration"
                  isMulti
                />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>

      <p className="mt-8">
        Input the social media stats for the relevant platforms by toggling the
        on button.
      </p>
      <FormField
        control={form.control}
        name="tikTokProfile.active"
        render={({ field }) => (
          <SocialMediaToggle
            icon={tikTokIcon}
            title="TikTok"
            enabled={field.value}
            setEnabled={(value) => field.onChange(value)}
          />
        )}
      />
      <TikTokProfileFields />
      <FormField
        control={form.control}
        name="instagramProfile.active"
        render={({ field }) => (
          <SocialMediaToggle
            icon={instagramIcon}
            title="Instagram"
            enabled={field.value}
            setEnabled={(value) => field.onChange(value)}
          />
        )}
      />
      <InstagramProfileFields />
      <FormField
        control={form.control}
        name="youtubeProfile.active"
        render={({ field }) => (
          <SocialMediaToggle
            icon={youtubeIcon}
            title="YouTube"
            enabled={field.value}
            setEnabled={(value) => field.onChange(value)}
          />
        )}
      />
      <YoutubeProfileFields />
      <FormField
        control={form.control}
        name="twitchProfile.active"
        render={({ field }) => (
          <SocialMediaToggle
            icon={twitchIcon}
            title="Twitch"
            enabled={field.value}
            setEnabled={(value) => field.onChange(value)}
          />
        )}
      />
      <TwitchProfileFields />

      <IconHeading icon={<TargetIcon />} title="Audience Demographics" />
      <div className="text-defaultSecondary -mt-4 mb-4">
        Select the majority for each field that this influencer has across all
        platforms. Soon enough Zano will have audience demographics pulled
        automatically with percentage splits for each platform.
      </div>
      <div className="flex flex-row gap-8">
        <div className="flex-1 flex flex-col gap-4">
          <FormField
            control={form.control}
            name="audienceCountryCode"
            render={({ field }) => (
              <FormItem>
                <CountrySelect
                  {...field}
                  onChange={(countryCode) => {
                    field.onChange(countryCode)
                  }}
                  isSearchable
                  isClearable
                  placeholder="Country"
                />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="audienceGender"
            render={({ field }) => (
              <FormItem>
                <Select
                  {...field}
                  value={audienceGenderOptions.find(
                    (option) => option.value === field.value
                  )}
                  onChange={(option) => {
                    field.onChange(option?.value || "")
                  }}
                  options={audienceGenderOptions}
                  isSearchable={false}
                  isClearable
                  placeholder="Gender"
                />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex-1 flex flex-col gap-4">
          <FormField
            control={form.control}
            name="audienceAge"
            render={({ field }) => (
              <FormItem>
                <Select
                  {...field}
                  value={ageRangeOptions.find(
                    (option) => option.value === field.value
                  )}
                  onChange={(option) => {
                    field.onChange(option?.value || "")
                  }}
                  options={ageRangeOptions}
                  isSearchable={false}
                  isClearable
                  placeholder="Age"
                />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>
    </div>
  )
}
