import { useQuery } from "@apollo/client"
import { useState } from "react"
import { AgencySortFieldEnum, SortDirectionEnum } from "~/__generated__/graphql"
import { AGENCIES_QUERY_DOCUMENT } from "./api"
// import { SearchValues } from "./schema"

const defaultSorts: Record<AgencySortFieldEnum, SortDirectionEnum> = {
  [AgencySortFieldEnum.Name]: SortDirectionEnum.Asc,
  [AgencySortFieldEnum.BusinessType]: SortDirectionEnum.Asc,
  [AgencySortFieldEnum.InfluencerCount]: SortDirectionEnum.Desc,
  [AgencySortFieldEnum.TiktokFollowers]: SortDirectionEnum.Desc,
  [AgencySortFieldEnum.InstagramFollowers]: SortDirectionEnum.Desc,
  [AgencySortFieldEnum.YoutubeSubscribers]: SortDirectionEnum.Desc,
  [AgencySortFieldEnum.TwitchSubscribers]: SortDirectionEnum.Desc,
  [AgencySortFieldEnum.ContactEmail]: SortDirectionEnum.Asc,
}

const DEFAULT_SORT_FIELD = AgencySortFieldEnum.InfluencerCount

export const useAgencies = () => {
  // const [lastSearchField, setLastSearchField] = useState<string | undefined>()
  // const [searchValues, setSearchValues] = useState<SearchValues>({})
  const [sortField, setSortField] = useState<AgencySortFieldEnum | undefined>(
    DEFAULT_SORT_FIELD
  )
  const [sortDir, setSortDir] = useState<SortDirectionEnum>(
    defaultSorts[DEFAULT_SORT_FIELD]
  )

  // Filtering by a query string uses its own ordering by default based on match quality,
  // so let's clear our custom one. Clicking a column again will reintroduce a custom order.
  // useEffect(() => {
  //   if (searchValues.query !== lastSearchField) {
  //     if (searchValues.query) {
  //       setSortField(undefined)
  //     } else {
  //       setSortField(DEFAULT_SORT_FIELD)
  //       setSortDir(defaultSorts[DEFAULT_SORT_FIELD])
  //     }
  //     setLastSearchField(searchValues.query)
  //   }
  // }, [lastSearchField, searchValues.query])

  const queryResult = useQuery(AGENCIES_QUERY_DOCUMENT, {
    variables: {
      sortField: sortField,
      sortDirection: sortDir,
      first: 50,
      agenciesCursor: null,
    },
  })

  const agencies = queryResult.data?.agencies.edges.map((e) => e.node) ?? []

  const onSort = (field: AgencySortFieldEnum) => {
    if (field === sortField) {
      setSortDir(
        sortDir === SortDirectionEnum.Asc
          ? SortDirectionEnum.Desc
          : SortDirectionEnum.Asc
      )
    } else {
      setSortField(field)
      setSortDir(defaultSorts[field])
    }
  }

  const onLoadNextPage = () => {
    queryResult.fetchMore({
      variables: {
        agenciesCursor: queryResult?.data?.agencies.pageInfo.endCursor,
      },
    })
  }

  return {
    ...queryResult,
    agencies,
    // setSearchValues,
    sortField,
    sortDir,
    onSort,
    onLoadNextPage,
    pageInfo: queryResult?.data?.agencies?.pageInfo,
  }
}
