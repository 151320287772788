import { gql } from "~/__generated__"

export const ROSTER_DATABASE_QUERY_DOCUMENT = gql(`
  query RosterDatabaseScreen(
    $myRoster: Boolean,
    $filters: InfluencersFiltersInput,
    $sortField: InfluencerSortFieldEnum,
    $sortDirection: SortDirectionEnum,

    $first: Int,
    $influencersCursor: String
  ) {
    influencers(
      myRoster: $myRoster,
      filters: $filters,
      sortField: $sortField,
      sortDirection: $sortDirection,

      first: $first,
      after: $influencersCursor
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
      edges {
        node {
          id
          deleted
          name
          category
          creatorTypes
          countryCode
          countryData {
            id
            name
          }
          agentEmail
          profileImageThumbnailUrl
          agency {
            id
            name
          }
          tikTokProfile {
            id
            followers
          }
          instagramProfile {
            id
            followers
          }
          youtubeProfile {
            id
            subscribers
          }
          twitchProfile {
            id
            subscribers
          }
        }
      }
    }
  }
`)
