import { cva, VariantProps } from "class-variance-authority"

const tagVariants = cva(
  "inline-block rounded-lg px-4 py-1 text-sm font-semibold",
  {
    variants: {
      variant: {
        default: "bg-blueLight text-blueDark",
        country: "bg-yellowLight text-yellowDark",
        creatorType: "bg-orangeLight text-orangeDark",
        category: "bg-purpleLight text-purpleDark",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
)

export type TagProps = VariantProps<typeof tagVariants> & {
  text: string
}

export const Tag = ({ text, variant }: TagProps) => {
  return <div className={tagVariants({ variant })}>{text}</div>
}
