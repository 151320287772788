import { useFormContext, useWatch } from "react-hook-form"
import { friendlyNumberAndBeyond, moneyAndBeyond } from "~/util/numbers"
import { SocialMediaFieldLabel } from "./SocialMediaFieldLabel"
import {
  FOLLOWERS_MAX,
  FOLLOWERS_MIN,
  FOLLOWERS_STEP,
  PRICING_CENTS_MAX,
  PRICING_CENTS_MIN,
  PRICING_CENTS_STEP,
  SearchValues,
  VIEWS_MAX,
  VIEWS_MIN,
  VIEWS_STEP,
} from "../../schema"

import twitchIcon from "~/images/icons/twitch.png"
import { RangeSlider } from "~/ui/RangeSlider"
import { SocialMediaFilter } from "./SocialMediaFilter"

export const TwitchFilter = () => {
  const form = useFormContext<SearchValues>()

  const enabled = useWatch({
    control: form.control,
    name: "twitchEnabled",
  })

  const subscribersMin =
    useWatch({
      control: form.control,
      name: "twitchSubscribersMin",
    }) || FOLLOWERS_MIN

  const subscribersMax =
    useWatch({
      control: form.control,
      name: "twitchSubscribersMax",
    }) || FOLLOWERS_MAX

  const avgStreamViewsMin =
    useWatch({
      control: form.control,
      name: "twitchAvgStreamViewsMin",
    }) || VIEWS_MIN

  const avgStreamViewsMax =
    useWatch({
      control: form.control,
      name: "twitchAvgStreamViewsMax",
    }) || VIEWS_MAX

  const concurrentViewersMin =
    useWatch({
      control: form.control,
      name: "twitchConcurrentViewersMin",
    }) || VIEWS_MIN

  const concurrentViewersMax =
    useWatch({
      control: form.control,
      name: "twitchConcurrentViewersMax",
    }) || VIEWS_MAX

  const streamRateMin =
    useWatch({
      control: form.control,
      name: "twitchStreamRateCentsMin",
    }) || PRICING_CENTS_MIN

  const streamRateMax =
    useWatch({
      control: form.control,
      name: "twitchStreamRateCentsMax",
    }) || PRICING_CENTS_MAX

  return (
    <SocialMediaFilter
      enabledFieldName="twitchEnabled"
      icon={twitchIcon}
      title="Twitch"
    >
      <div>
        <SocialMediaFieldLabel text="Subscribers" />
        <RangeSlider
          min={FOLLOWERS_MIN}
          max={FOLLOWERS_MAX}
          step={FOLLOWERS_STEP}
          value={[subscribersMin, subscribersMax]}
          onAfterChange={(values) => {
            const [min, max] = values
            form.setValue("twitchSubscribersMin", min)
            form.setValue("twitchSubscribersMax", max)
          }}
          disabled={!enabled}
          format={(n) => friendlyNumberAndBeyond(n, FOLLOWERS_MAX, 2)}
        />
      </div>
      <div>
        <SocialMediaFieldLabel text="Average Stream Views" />
        <RangeSlider
          min={VIEWS_MIN}
          max={VIEWS_MAX}
          step={VIEWS_STEP}
          value={[avgStreamViewsMin, avgStreamViewsMax]}
          onAfterChange={(values) => {
            const [min, max] = values
            form.setValue("twitchAvgStreamViewsMin", min)
            form.setValue("twitchAvgStreamViewsMax", max)
          }}
          disabled={!enabled}
          format={(n) => friendlyNumberAndBeyond(n, VIEWS_MAX)}
        />
      </div>
      <div>
        <SocialMediaFieldLabel text="CCV" />
        <RangeSlider
          min={VIEWS_MIN}
          max={VIEWS_MAX}
          step={VIEWS_STEP}
          value={[concurrentViewersMin, concurrentViewersMax]}
          onAfterChange={(values) => {
            const [min, max] = values
            form.setValue("twitchConcurrentViewersMin", min)
            form.setValue("twitchConcurrentViewersMax", max)
          }}
          disabled={!enabled}
          format={(n) => friendlyNumberAndBeyond(n, VIEWS_MAX)}
        />
      </div>
      <div>
        <SocialMediaFieldLabel text="Stream Rate" />
        <RangeSlider
          min={PRICING_CENTS_MIN / 100}
          max={PRICING_CENTS_MAX / 100}
          step={PRICING_CENTS_STEP / 100}
          value={[streamRateMin / 100, streamRateMax / 100]}
          onAfterChange={(values) => {
            const [min, max] = values
            form.setValue("twitchStreamRateCentsMin", min * 100)
            form.setValue("twitchStreamRateCentsMax", max * 100)
          }}
          disabled={!enabled}
          format={(n) => moneyAndBeyond(n, PRICING_CENTS_MAX / 100)}
        />
      </div>
    </SocialMediaFilter>
  )
}
