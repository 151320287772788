import {
  CategoryEnum,
  CreatorTypeEnum,
  PlatformEnum,
} from "~/__generated__/graphql"

function naiveSnakeToHuman(input: string): string {
  const words = input.split("_")
  return words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ")
}

export function formatCategory(category: CategoryEnum): string {
  return naiveSnakeToHuman(category)
}

export function formatCreatorType(creatorType: CreatorTypeEnum): string {
  if (creatorType === CreatorTypeEnum.Ugc) {
    return "UGC"
  }
  return naiveSnakeToHuman(creatorType)
}

export function formatPlatform(platform: PlatformEnum): string {
  switch (platform) {
    case PlatformEnum.Tiktok:
      return "TikTok"
    case PlatformEnum.Youtube:
      return "YouTube"
    default:
      return naiveSnakeToHuman(platform)
  }
}
