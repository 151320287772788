import toast from "react-hot-toast"
import { BusinessTypeEnum, JobTitleEnum } from "~/__generated__/graphql"
import { useSafeMutation } from "~/common/useSafeMutation"
import { UserAccountForm, UserAccountFormValues } from "~/forms/UserAccountForm"
import { ADMIN_CREATE_USER_MUTATION } from "../api"

export const NewUserModal: React.FC<{
  onClose: () => void
  onUserCreated: () => void
}> = ({ onClose, onUserCreated }) => {
  const [createUser, { loading, error }] = useSafeMutation(
    ADMIN_CREATE_USER_MUTATION
  )

  const onSubmit = async (values: UserAccountFormValues) => {
    const { data } = await createUser({
      variables: {
        input: {
          userInput: {
            firstName: values.firstName,
            lastName: values.lastName,
            jobTitle: (values.jobTitle || null) as JobTitleEnum | null,
            agency: {
              ...values.agency,
              businessType: values.agency.businessType as BusinessTypeEnum,
            },
            password: values.password,
            email: values.email,
          },
        },
      },
    })

    if (data?.manualUserCreate?.user) {
      toast.success("User created successfully")
      onUserCreated()
      onClose()
    }
  }

  return (
    <div className="p-6 flex flex-col flex-1">
      <h2 className="text-2xl font-bold mb-4">Create New User</h2>
      <UserAccountForm
        initialValues={{
          firstName: "",
          lastName: "",
          jobTitle: "",
          agency: {
            agentName: "",
            contactEmail: "",
            logo: undefined,
            name: "",
            phone: "",
            website: "",
            businessType: BusinessTypeEnum.Talent,
          },
          password: "",
          email: "",
        }}
        onSubmit={onSubmit}
        isLoading={loading}
        error={error}
        onChangePassword={() => {}} // Not needed for new user creation
        email="" // Not needed for new user creation
        logoThumbnailUrl={null}
        isNewUser={true} // Add this prop to UserAccountForm to handle new user specific fields
        onCancel={onClose}
      />
    </div>
  )
}
