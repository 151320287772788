import { CreatorTypeEnum } from "~/__generated__/graphql"
import { CreatorTypeIcon } from "~/components/CreatorTypeIcon"
import { cn } from "~/common/shadcn-utils"
import Text from "~/ui/typography"
import { DashboardCard } from "./DashboardCard"

const categoryColors = ["#E66792", "#77CCEA", "#F6B396", "#9EDF9C", "#DFDC9C"]

const getCategoryColor = (index: number): string => {
  return index < categoryColors.length ? categoryColors[index] : "transparent"
}

interface StatCardProps {
  title: string
  stats: Array<{
    label: string
    count: number
    emoji?: string
    creatorType?: CreatorTypeEnum
    icon?: any
  }>
  showColorCircle?: boolean
}

export const StatCard: React.FC<StatCardProps> = ({
  title,
  stats,
  showColorCircle = false,
}) => {
  return (
    <DashboardCard title={<Text variant="body2">{title}</Text>}>
      <ul className="space-y-2">
        {stats.map((stat, index) => (
          <Text
            as="li"
            variant="body4"
            key={index}
            className="flex items-center justify-between text-gray-1e"
          >
            <span className="truncate flex items-center" title={stat.label}>
              {showColorCircle && (
                <span
                  className={cn("w-3 h-3 rounded-full mr-2")}
                  style={{ backgroundColor: getCategoryColor(index) }}
                />
              )}
              {stat.emoji && <span className="mr-1">{stat.emoji}</span>}
              {stat.creatorType && (
                <span className="mr-2">
                  <CreatorTypeIcon
                    creatorType={stat.creatorType}
                    className="w-5 h-5"
                  />
                </span>
              )}
              {stat.icon && (
                <img {...stat.icon} alt="" className="mr-2 w-5 h-5" />
              )}
              {stat.label}
            </span>
            <span className="text-right text-sm text-gray-500">
              {stat.count}
            </span>
          </Text>
        ))}
      </ul>
    </DashboardCard>
  )
}
