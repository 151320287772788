import React from "react"
import { GroupBase, SelectInstance } from "react-select"
import { useCountries } from "~/common/useCountries"
import { Select, SelectProps } from "./Select"

type CountryOption = { value: string; label: string }

export const CountrySelect = React.forwardRef<
  SelectInstance<CountryOption, false, GroupBase<CountryOption>>,
  Omit<
    SelectProps<CountryOption, false, GroupBase<CountryOption>>,
    "onChange" | "value"
  > & {
    value: string
    onChange: (countryCode: string) => void
  }
>((props, ref) => {
  const { countries } = useCountries()
  const { value, onChange, ...rest } = props

  const countryOptions =
    countries.map((country) => ({
      value: country.code,
      label: country.name,
    })) || []

  return (
    <Select
      value={countryOptions.find((option) => option.value === value)}
      onChange={(option) => {
        onChange(option?.value || "")
      }}
      options={countryOptions}
      isSearchable
      isClearable
      {...rest}
      ref={ref}
    />
  )
})
