import { useState } from "react"
import { useNavigate } from "react-router-dom"
import invariant from "tiny-invariant"
import { getFragmentData } from "~/__generated__"
import { afterLoginPath } from "~/common/paths"
import { useSafeMutation } from "~/common/useSafeMutation"
import {
  BULK_UPLOAD_INFLUENCERS_MUTATION,
  BULK_UPLOAD_RESULT_FRAGMENT,
} from "~/screens/influencers/bulk-upload/api"
import { BulkUpload } from "~/screens/influencers/bulk-upload/components/BulkUpload"
import { BulkUploadResults } from "~/screens/influencers/bulk-upload/components/BulkUploadResults"
import { Button } from "~/shadcn/ui/button"
import { H1 } from "~/ui/typography"
import { UPDATE_USER_MUTATION } from "../api"
import { HelpText } from "./components/HelpText"

export const OnboardingYourRosterScreen = () => {
  const [runUpdateUser] = useSafeMutation(UPDATE_USER_MUTATION)
  const [runBulkUpload, bulkUploadResult] = useSafeMutation(
    BULK_UPLOAD_INFLUENCERS_MUTATION
  )
  const processingResult = getFragmentData(
    BULK_UPLOAD_RESULT_FRAGMENT,
    bulkUploadResult.data?.influencerBulkUpload?.result
  )
  const navigate = useNavigate()
  const [csvSignedId, setCsvSignedId] = useState<string | null>(null)

  const onCompleteOnboarding = async () => {
    const { data, errors } = await runUpdateUser({
      variables: {
        input: {
          onboarded: true,
        },
      },
    })
    if (errors) {
      console.error(errors)
    } else if (data) {
      navigate(afterLoginPath({}))
    } else {
      console.error("unexpected response", { data, errors })
    }
  }

  const onSkip = onCompleteOnboarding

  const onSubmit = async () => {
    invariant(csvSignedId, "uploaded csv is required")
    const { data } = await runBulkUpload({
      variables: {
        input: {
          csvFile: csvSignedId,
          dryRun: false,
        },
      },
    })

    const result = getFragmentData(
      BULK_UPLOAD_RESULT_FRAGMENT,
      data?.influencerBulkUpload?.result
    )

    if (result?.success) {
      onCompleteOnboarding()
    } else {
      console.error("unexpected response", { data })
    }
  }

  return (
    <div className="flex-1 flex flex-col self-stretch">
      <div className="text-defaultSecondary mb-8">Step 3/3</div>
      <H1 className="mb-2">Your Roster</H1>
      <p className="text-hoveredSecondary mb-8">
        Add at least one influencer from your roster to the live database.
        Alternatively, skip this step for now and add your influencers in
        manually in the “My Roster” section.
      </p>
      <hr className="mb-4 border-0 border-b border-gray30" />
      <BulkUpload
        onUpload={async (signedId) => {
          setCsvSignedId(signedId)
          await runBulkUpload({
            variables: {
              input: {
                csvFile: signedId,
                dryRun: true,
              },
            },
          })
        }}
        extraContent={
          <>
            <BulkUploadResults
              result={processingResult}
              errorDescription={`Found errors in csv file. Please try to fix the file or click "skip
            for now" if you prefer to do this later.`}
            />
            <HelpText />
          </>
        }
        processing={bulkUploadResult.loading}
      />

      <div className="self-stretch flex-1 flex-grow flex justify-between items-end gap-4">
        <div className="flex-grow">
          <Button type="button" variant="ghost" onClick={() => navigate(-1)}>
            Back
          </Button>
        </div>
        <Button variant="secondary" type="button" onClick={onSkip}>
          Skip for now
        </Button>
        <Button
          type="button"
          onClick={onSubmit}
          disabled={!processingResult?.success}
        >
          Create my Account
        </Button>
      </div>
    </div>
  )
}
