import * as React from "react"

import { cn } from "~/common/shadcn-utils"
import { Input } from "~/shadcn/ui/input"
import { Label } from "~/shadcn/ui/label"
import { Textarea } from "~/shadcn/ui/textarea"

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  prefix?: string
  postfix?: string
  icon?: React.ReactNode
}

const FloatingInput = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, prefix, postfix, icon, ...props }, ref) => {
    return (
      <>
        {icon && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            {icon}
          </div>
        )}
        {prefix && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <span className="text-gray50 sm:text-sm">{prefix}</span>
          </div>
        )}
        <Input
          placeholder=" "
          className={cn(
            "peer border-gray20 hover:border-gray40 text-base py-1.5",
            { "pl-7": !!prefix, "pr-7": !!postfix, "pl-9": !!icon },
            className
          )}
          ref={ref}
          {...props}
        />
        {postfix && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <span id="price-currency" className="text-gray50 sm:text-sm">
              {postfix}
            </span>
          </div>
        )}
      </>
    )
  }
)
FloatingInput.displayName = "FloatingInput"

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const FloatingTextarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, ...props }, ref) => {
    return (
      <Textarea
        placeholder=" "
        className={cn(
          "peer border-gray20 hover:border-gray40 text-base",
          className
        )}
        ref={ref}
        {...props}
      />
    )
  }
)
FloatingTextarea.displayName = "FloatingTextarea"

const FloatingLabel = React.forwardRef<
  React.ElementRef<typeof Label>,
  React.ComponentPropsWithoutRef<typeof Label> & {
    icon?: React.ReactNode
    verticalPosition?: "top" | "center"
  }
>(({ className, icon, verticalPosition = "center", ...props }, ref) => {
  return (
    <Label
      className={cn(
        "peer-focus:secondary peer-focus:dark:secondary absolute start-2 top-2 z-10 origin-[0] -translate-y-6 scale-75 transform bg-background px-2 text-base text-gray50 duration-300 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:px-2 dark:bg-background rtl:peer-focus:left-auto rtl:peer-focus:translate-x-1/2 peer-focus:bg-white bg-white",
        {
          "ml-5": !!icon,
          "peer-placeholder-shown:top-6": verticalPosition === "top",
          "peer-placeholder-shown:top-1/2": verticalPosition === "center",
        },

        className
      )}
      ref={ref}
      {...props}
    />
  )
})
FloatingLabel.displayName = "FloatingLabel"

type FloatingLabelInputProps = InputProps & { label?: string }

const FloatingLabelInput = React.forwardRef<
  React.ElementRef<typeof FloatingInput>,
  React.PropsWithoutRef<FloatingLabelInputProps>
>(({ id, label, icon, ...props }, ref) => {
  const genId = React.useId()
  let _id = id ?? genId

  return (
    <div className="relative">
      <FloatingInput ref={ref} id={_id} icon={icon} {...props} />
      <FloatingLabel icon={icon} htmlFor={_id}>
        {label}
      </FloatingLabel>
    </div>
  )
})
FloatingLabelInput.displayName = "FloatingLabelInput"

type FloatingLabelTextareaProps = TextareaProps & { label?: string }

const FloatingLabelTextarea = React.forwardRef<
  React.ElementRef<typeof FloatingTextarea>,
  React.PropsWithoutRef<FloatingLabelTextareaProps>
>(({ id, label, ...props }, ref) => {
  return (
    <div className="relative">
      <FloatingTextarea ref={ref} id={id} {...props} />
      <FloatingLabel htmlFor={id} verticalPosition="top">
        {label}
      </FloatingLabel>
    </div>
  )
})
FloatingLabelInput.displayName = "FloatingLabelInput"

export {
  FloatingInput,
  FloatingLabel,
  FloatingLabelInput,
  FloatingLabelTextarea,
}
