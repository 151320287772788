import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom"
import * as paths from "~/common/paths"
import { ErrorBoundary } from "./components/ErrorBoundary"
import { AnalyticsTracking } from "./layouts/AnalyticsTracking"
import { AuthenticatedLayout } from "./layouts/AuthenticatedLayout"
import { OnboardingLayout } from "./layouts/OnboardingLayout"
import { RequireAdmin } from "./layouts/RequireAdmin"
import { RequireUserNotOnboarded } from "./layouts/RequireUserNotOnboarded"
import { RequireUserOnboarded } from "./layouts/RequireUserOnboarded"
import { RequireUserSignedIn } from "./layouts/RequireUserSignedIn"
import { RequireUserSignedOut } from "./layouts/RequireUserSignedOut"
import { AccountInactiveScreen } from "./screens/AccountInactiveScreen"
import { AdminUsersScreen } from "./screens/admin/users/AdminUsersScreen"
import { AgenciesScreen } from "./screens/agencies/AgenciesScreen"
import { DashboardScreen } from "./screens/dashboard/DashboardScreen"
import { LoadingScreen } from "./screens/LoadingScreen"
import { OnboardingAgencyDetailsScreen } from "./screens/onboarding/agency-details/OnboardingAgencyDetailsScreen"
import { ForgotPasswordScreen } from "./screens/onboarding/forgot-password/ForgotPasswordScreen"
import { OnboardingPersonalDetailsScreen } from "./screens/onboarding/personal-details/OnboardingPersonalDetailsScreen"
import { ResetPasswordScreen } from "./screens/onboarding/reset-password/ResetPasswordScreen"
import { SignInScreen } from "./screens/onboarding/sign-in/SignInScreen"
import { SignUpScreen } from "./screens/onboarding/sign-up/SignUpScreen"
import { OnboardingYourRosterScreen } from "./screens/onboarding/your-roster/OnboardingYourRosterScreen"
import { MyRosterScreen } from "./screens/rosters/my-roster/MyRosterScreen"
import { RosterDatabaseScreen } from "./screens/rosters/roster-database/RosterDatabaseScreen"
import { SettingsAccountScreen } from "./screens/settings/SettingsAccountScreen"

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<ErrorBoundary />}>
      <Route element={<AnalyticsTracking />}>
        <Route path={paths.loadingPath.pattern} element={<LoadingScreen />} />

        <Route element={<RequireUserSignedIn />}>
          <Route element={<RequireUserOnboarded />}>
            <Route element={<AuthenticatedLayout />}>
              <Route
                path={paths.dashboardPath.pattern}
                element={<DashboardScreen />}
              />
              <Route
                path={paths.rosterPath.pattern}
                element={<RosterDatabaseScreen />}
              />
              <Route
                path={paths.agenciesPath.pattern}
                element={<AgenciesScreen />}
              />
              <Route
                path={paths.myRosterPath.pattern}
                element={<MyRosterScreen />}
              />
              <Route
                path={paths.settingsAccountPath.pattern}
                element={<SettingsAccountScreen />}
              />

              <Route element={<RequireAdmin />}>
                <Route
                  path={paths.adminUsersPath.pattern}
                  element={<AdminUsersScreen />}
                />
              </Route>
            </Route>
          </Route>

          <Route element={<RequireUserNotOnboarded />}>
            <Route element={<OnboardingLayout />}>
              <Route
                path={paths.onboardingPersonalDetailsPath.pattern}
                element={<OnboardingPersonalDetailsScreen />}
              />
              <Route
                path={paths.onboardingAgencyDetailsPath.pattern}
                element={<OnboardingAgencyDetailsScreen />}
              />
              <Route
                path={paths.onboardingYourRosterPath.pattern}
                element={<OnboardingYourRosterScreen />}
              />
            </Route>
          </Route>
        </Route>

        <Route element={<RequireUserSignedOut />}>
          <Route
            index
            path={paths.signInPath.pattern}
            element={<SignInScreen />}
          />
          <Route path={paths.signUpPath.pattern} element={<SignUpScreen />} />
          <Route
            path={paths.forgotPasswordPath.pattern}
            element={<ForgotPasswordScreen />}
          />
          <Route
            path={paths.resetPasswordPath.pattern}
            element={<ResetPasswordScreen />}
          />
        </Route>
        <Route
          element={<AccountInactiveScreen />}
          path={paths.accountInactivePath.pattern}
        />
      </Route>
    </Route>
  )
)
