import { zodResolver } from "@hookform/resolvers/zod"
import z from "zod"
import {
  AgeRangeEnum,
  AudienceGenderEnum,
  CategoryEnum,
  CollaborationTypeEnum,
  CreatorTypeEnum,
  ExclusivityEnum,
  GenderEnum,
  LanguageEnum,
} from "~/__generated__/graphql"

export const AGE_MIN = 17
export const AGE_MAX = 100

export const FOLLOWERS_MIN = 0
export const FOLLOWERS_MAX = 2000000
export const FOLLOWERS_STEP = 10000

export const VIEWS_MIN = 0
export const VIEWS_MAX = 1000000
export const VIEWS_STEP = 5000

export const ENG_RATE_MIN = 0
export const ENG_RATE_MAX = 1

export const PRICING_CENTS_MIN = 0
export const PRICING_CENTS_MAX = 2000000
export const PRICING_CENTS_STEP = 10000

const searchSchema = z.object({
  creatorTypes: z.array(z.string()).optional(),
  categories: z.array(z.string()).optional(),
  tagIds: z.array(z.string()).optional(),
  countryCodes: z.array(z.string()).optional(),
  audienceCountryCodes: z.array(z.string()).optional(),
  genders: z.array(z.string()).optional(),
  audienceGenders: z.array(z.string()).optional(),
  ageMin: z.number().optional(),
  ageMax: z.number().optional(),
  audienceAges: z.array(z.string()).optional(),
  collaborationTypes: z.array(z.string()).optional(),
  languages: z.array(z.string()).optional(),
  exclusivities: z.array(z.string()).optional(),
  agencyIds: z.array(z.string()).optional(),

  query: z.string().optional(),

  tikTokEnabled: z.boolean().optional(),
  tikTokFollowersMin: z.number().optional(),
  tikTokFollowersMax: z.number().optional(),
  tikTokAvgViewsMin: z.number().optional(),
  tikTokAvgViewsMax: z.number().optional(),
  tikTokEngagementRateMin: z.number().optional(),
  tikTokEngagementRateMax: z.number().optional(),
  tikTokVideoRateCentsMin: z.number().optional(),
  tikTokVideoRateCentsMax: z.number().optional(),

  instagramEnabled: z.boolean().optional(),
  instagramFollowersMin: z.number().optional(),
  instagramFollowersMax: z.number().optional(),
  instagramAvgViewsMin: z.number().optional(),
  instagramAvgViewsMax: z.number().optional(),
  instagramEngagementRateMin: z.number().optional(),
  instagramEngagementRateMax: z.number().optional(),
  instagramReelsRateCentsMin: z.number().optional(),
  instagramReelsRateCentsMax: z.number().optional(),

  youtubeEnabled: z.boolean().optional(),
  youtubeSubscribersMin: z.number().optional(),
  youtubeSubscribersMax: z.number().optional(),
  youtubeVideosAvgViewsMin: z.number().optional(),
  youtubeVideosAvgViewsMax: z.number().optional(),
  youtubeVideosEngagementRateMin: z.number().optional(),
  youtubeVideosEngagementRateMax: z.number().optional(),
  youtubeShortsAvgViewsMin: z.number().optional(),
  youtubeShortsAvgViewsMax: z.number().optional(),
  youtubeShortsEngagementRateMin: z.number().optional(),
  youtubeShortsEngagementRateMax: z.number().optional(),
  youtubeIntegrationRateCentsMin: z.number().optional(),
  youtubeIntegrationRateCentsMax: z.number().optional(),
  youtubeDedicationRateCentsMin: z.number().optional(),
  youtubeDedicationRateCentsMax: z.number().optional(),
  youtubeShortsRateCentsMin: z.number().optional(),
  youtubeShortsRateCentsMax: z.number().optional(),

  twitchEnabled: z.boolean().optional(),
  twitchSubscribersMin: z.number().optional(),
  twitchSubscribersMax: z.number().optional(),
  twitchAvgStreamViewsMin: z.number().optional(),
  twitchAvgStreamViewsMax: z.number().optional(),
  twitchConcurrentViewersMin: z.number().optional(),
  twitchConcurrentViewersMax: z.number().optional(),
  twitchStreamRateCentsMin: z.number().optional(),
  twitchStreamRateCentsMax: z.number().optional(),
})

export type SearchValues = z.infer<typeof searchSchema>
export const searchResolver = zodResolver(searchSchema)

export const searchDefaultValues: SearchValues = {
  creatorTypes: [],
  categories: [],
  tagIds: [],
  countryCodes: [],
  audienceCountryCodes: [],
  genders: [],
  audienceGenders: [],
  audienceAges: [],
  collaborationTypes: [],
  languages: [],
  exclusivities: [],
  agencyIds: [],
  tikTokEnabled: false,
  instagramEnabled: false,
  youtubeEnabled: false,
  twitchEnabled: false,
  query: "",
}

export const searchValuesToGraphql = (values: SearchValues) => {
  return {
    ...values,
    creatorTypes: values.creatorTypes as CreatorTypeEnum[] | undefined,
    categories: values.categories as CategoryEnum[] | undefined,
    genders: values.genders as GenderEnum[] | undefined,
    audienceGenders: values.audienceGenders as AudienceGenderEnum[] | undefined,
    collaborationTypes: values.collaborationTypes as
      | CollaborationTypeEnum[]
      | undefined,
    languages: values.languages as LanguageEnum[] | undefined,
    exclusivities: values.exclusivities as ExclusivityEnum[] | undefined,
    ageMin:
      values.ageMin && values.ageMin > AGE_MIN ? values.ageMin : undefined,
    ageMax:
      values.ageMax && values.ageMax < AGE_MAX ? values.ageMax : undefined,
    audienceAges: values.audienceAges as AgeRangeEnum[] | undefined,

    tikTokFollowersMin:
      values.tikTokEnabled &&
      values.tikTokFollowersMin &&
      values.tikTokFollowersMin > FOLLOWERS_MIN
        ? values.tikTokFollowersMin
        : undefined,
    tikTokFollowersMax:
      values.tikTokEnabled &&
      values.tikTokFollowersMax &&
      values.tikTokFollowersMax < FOLLOWERS_MAX
        ? values.tikTokFollowersMax
        : undefined,
    tikTokAvgViewsMin:
      values.tikTokEnabled &&
      values.tikTokAvgViewsMin &&
      values.tikTokAvgViewsMin > VIEWS_MIN
        ? values.tikTokAvgViewsMin
        : undefined,
    tikTokAvgViewsMax:
      values.tikTokEnabled &&
      values.tikTokAvgViewsMax &&
      values.tikTokAvgViewsMax < VIEWS_MAX
        ? values.tikTokAvgViewsMax
        : undefined,
    tikTokEngagementRateMin:
      values.tikTokEnabled &&
      values.tikTokEngagementRateMin &&
      values.tikTokEngagementRateMin > ENG_RATE_MIN
        ? values.tikTokEngagementRateMin
        : undefined,
    tikTokEngagementRateMax:
      values.tikTokEnabled &&
      values.tikTokEngagementRateMax &&
      values.tikTokEngagementRateMax < ENG_RATE_MAX
        ? values.tikTokEngagementRateMax
        : undefined,
    tikTokVideoRateCentsMin:
      values.tikTokEnabled &&
      values.tikTokVideoRateCentsMin &&
      values.tikTokVideoRateCentsMin > PRICING_CENTS_MIN
        ? values.tikTokVideoRateCentsMin
        : undefined,
    tikTokVideoRateCentsMax:
      values.tikTokEnabled &&
      values.tikTokVideoRateCentsMax &&
      values.tikTokVideoRateCentsMax < PRICING_CENTS_MAX
        ? values.tikTokVideoRateCentsMax
        : undefined,

    instagramFollowersMin:
      values.instagramEnabled &&
      values.instagramFollowersMin &&
      values.instagramFollowersMin > FOLLOWERS_MIN
        ? values.instagramFollowersMin
        : undefined,
    instagramFollowersMax:
      values.instagramEnabled &&
      values.instagramFollowersMax &&
      values.instagramFollowersMax < FOLLOWERS_MAX
        ? values.instagramFollowersMax
        : undefined,
    instagramAvgViewsMin:
      values.instagramEnabled &&
      values.instagramAvgViewsMin &&
      values.instagramAvgViewsMin > VIEWS_MIN
        ? values.instagramAvgViewsMin
        : undefined,
    instagramAvgViewsMax:
      values.instagramEnabled &&
      values.instagramAvgViewsMax &&
      values.instagramAvgViewsMax < VIEWS_MAX
        ? values.instagramAvgViewsMax
        : undefined,
    instagramEngagementRateMin:
      values.instagramEnabled &&
      values.instagramEngagementRateMin &&
      values.instagramEngagementRateMin > ENG_RATE_MIN
        ? values.instagramEngagementRateMin
        : undefined,
    instagramEngagementRateMax:
      values.instagramEnabled &&
      values.instagramEngagementRateMax &&
      values.instagramEngagementRateMax < ENG_RATE_MAX
        ? values.instagramEngagementRateMax
        : undefined,
    instagramReelsRateCentsMin:
      values.instagramEnabled &&
      values.instagramReelsRateCentsMin &&
      values.instagramReelsRateCentsMin > PRICING_CENTS_MIN
        ? values.instagramReelsRateCentsMin
        : undefined,
    instagramReelsRateCentsMax:
      values.instagramEnabled &&
      values.instagramReelsRateCentsMax &&
      values.instagramReelsRateCentsMax < PRICING_CENTS_MAX
        ? values.instagramReelsRateCentsMax
        : undefined,

    youtubeSubscribersMin:
      values.youtubeEnabled &&
      values.youtubeSubscribersMin &&
      values.youtubeSubscribersMin > FOLLOWERS_MIN
        ? values.youtubeSubscribersMin
        : undefined,
    youtubeSubscribersMax:
      values.youtubeEnabled &&
      values.youtubeSubscribersMax &&
      values.youtubeSubscribersMax < FOLLOWERS_MAX
        ? values.youtubeSubscribersMax
        : undefined,
    youtubeShortsAvgViewsMin:
      values.youtubeEnabled &&
      values.youtubeShortsAvgViewsMin &&
      values.youtubeShortsAvgViewsMin > VIEWS_MIN
        ? values.youtubeShortsAvgViewsMin
        : undefined,
    youtubeShortsAvgViewsMax:
      values.youtubeEnabled &&
      values.youtubeShortsAvgViewsMax &&
      values.youtubeShortsAvgViewsMax < VIEWS_MAX
        ? values.youtubeShortsAvgViewsMax
        : undefined,
    youtubeShortsEngagementRateMin:
      values.youtubeEnabled &&
      values.youtubeShortsEngagementRateMin &&
      values.youtubeShortsEngagementRateMin > ENG_RATE_MIN
        ? values.youtubeShortsEngagementRateMin
        : undefined,
    youtubeShortsEngagementRateMax:
      values.youtubeEnabled &&
      values.youtubeShortsEngagementRateMax &&
      values.youtubeShortsEngagementRateMax < ENG_RATE_MAX
        ? values.youtubeShortsEngagementRateMax
        : undefined,
    youtubeDedicationRateCentsMin:
      values.youtubeEnabled &&
      values.youtubeDedicationRateCentsMin &&
      values.youtubeDedicationRateCentsMin > PRICING_CENTS_MIN
        ? values.youtubeDedicationRateCentsMin
        : undefined,
    youtubeDedicationRateCentsMax:
      values.youtubeEnabled &&
      values.youtubeDedicationRateCentsMax &&
      values.youtubeDedicationRateCentsMax < PRICING_CENTS_MAX
        ? values.youtubeDedicationRateCentsMax
        : undefined,
    youtubeIntegrationRateCentsMin:
      values.youtubeEnabled &&
      values.youtubeIntegrationRateCentsMin &&
      values.youtubeIntegrationRateCentsMin > PRICING_CENTS_MIN
        ? values.youtubeIntegrationRateCentsMin
        : undefined,
    youtubeIntegrationRateCentsMax:
      values.youtubeEnabled &&
      values.youtubeIntegrationRateCentsMax &&
      values.youtubeIntegrationRateCentsMax < PRICING_CENTS_MAX
        ? values.youtubeIntegrationRateCentsMax
        : undefined,
    youtubeShortsRateCentsMin:
      values.youtubeEnabled &&
      values.youtubeShortsRateCentsMin &&
      values.youtubeShortsRateCentsMin > PRICING_CENTS_MIN
        ? values.youtubeShortsRateCentsMin
        : undefined,
    youtubeShortsRateCentsMax:
      values.youtubeEnabled &&
      values.youtubeShortsRateCentsMax &&
      values.youtubeShortsRateCentsMax < PRICING_CENTS_MAX
        ? values.youtubeShortsRateCentsMax
        : undefined,

    twitchSubscribersMin:
      values.twitchEnabled &&
      values.twitchSubscribersMin &&
      values.twitchSubscribersMin > FOLLOWERS_MIN
        ? values.twitchSubscribersMin
        : undefined,
    twitchSubscribersMax:
      values.twitchEnabled &&
      values.twitchSubscribersMax &&
      values.twitchSubscribersMax < FOLLOWERS_MAX
        ? values.twitchSubscribersMax
        : undefined,
    twitchAvgStreamViewsMin:
      values.twitchEnabled &&
      values.twitchAvgStreamViewsMin &&
      values.twitchAvgStreamViewsMin > VIEWS_MIN
        ? values.twitchAvgStreamViewsMin
        : undefined,
    twitchAvgStreamViewsMax:
      values.twitchEnabled &&
      values.twitchAvgStreamViewsMax &&
      values.twitchAvgStreamViewsMax < VIEWS_MAX
        ? values.twitchAvgStreamViewsMax
        : undefined,
    twitchConcurrentViewersMin:
      values.twitchEnabled &&
      values.twitchConcurrentViewersMin &&
      values.twitchConcurrentViewersMin > VIEWS_MIN
        ? values.twitchConcurrentViewersMin
        : undefined,
    twitchConcurrentViewersMax:
      values.twitchEnabled &&
      values.twitchConcurrentViewersMax &&
      values.twitchConcurrentViewersMax < VIEWS_MAX
        ? values.twitchConcurrentViewersMax
        : undefined,
    twitchStreamRateCentsMin:
      values.twitchEnabled &&
      values.twitchStreamRateCentsMin &&
      values.twitchStreamRateCentsMin > PRICING_CENTS_MIN
        ? values.twitchStreamRateCentsMin
        : undefined,
    twitchStreamRateCentsMax:
      values.twitchEnabled &&
      values.twitchStreamRateCentsMax &&
      values.twitchStreamRateCentsMax < PRICING_CENTS_MAX
        ? values.twitchStreamRateCentsMax
        : undefined,
  }
}
