import { FullInfluencerFragment } from "~/__generated__/graphql"
import {
  categoryName,
  collaborationTypeNames,
  creatorTypeName,
  exclusivityName,
} from "~/common/enums"
import { Avatar } from "~/ui/Avatar"
import { Tag } from "~/ui/Tag"

import tikTokIcon from "~/images/icons/tiktok.png"
import instagramIcon from "~/images/icons/instagram.png"
import youtubeIcon from "~/images/icons/youtube.png"
import twitchIcon from "~/images/icons/twitch.png"
import { CopyButton } from "~/ui/CopyButton"

const SocialMediaIcon = ({
  url,
  icon,
  alt,
}: {
  url: string
  icon: string
  alt: string
}) => {
  return (
    <a href={url} target="_blank" rel="noreferrer">
      <img src={icon} alt={alt} />
    </a>
  )
}

const countryTagName = (influencer: FullInfluencerFragment) => {
  if (influencer.countryData) {
    return `${influencer.countryData.emojiFlag} ${influencer.countryData.name}`
  }
  return influencer.countryCode || ""
}

export const Heading = ({
  influencer,
}: {
  influencer: FullInfluencerFragment
}) => {
  return (
    <div className="flex flex-row gap-4 mb-4">
      <div>
        <Avatar
          src={influencer.profileImageThumbnailUrl}
          alt={influencer.name}
          size="lg"
        />
      </div>
      <div className="flex-grow flex flex-col gap-2">
        <div className="font-header font-bold text-2xl">{influencer.name}</div>
        <div className="flex flex-row gap-2 items-center">
          {influencer.tikTokProfile?.url && (
            <SocialMediaIcon
              url={influencer.tikTokProfile.url}
              icon={tikTokIcon}
              alt="TikTok"
            />
          )}
          {influencer.instagramProfile?.url && (
            <SocialMediaIcon
              url={influencer.instagramProfile.url}
              icon={instagramIcon}
              alt="Instagram"
            />
          )}
          {influencer.youtubeProfile?.url && (
            <SocialMediaIcon
              url={influencer.youtubeProfile.url}
              icon={youtubeIcon}
              alt="Youtube"
            />
          )}
          {influencer.twitchProfile?.url && (
            <SocialMediaIcon
              url={influencer.twitchProfile.url}
              icon={twitchIcon}
              alt="Twitch"
            />
          )}
        </div>
        <div className="text-defaultSecondary">
          {collaborationTypeNames(influencer.collaborationTypes).join(" • ")}
        </div>
        <div className="flex flex-wrap gap-2">
          {influencer.countryCode && (
            <Tag variant="country" text={countryTagName(influencer)} />
          )}
          {influencer.creatorTypes?.map((creatorType) => (
            <Tag
              key={creatorType}
              variant="creatorType"
              text={creatorTypeName(creatorType)}
            />
          ))}
          {influencer.category && (
            <Tag variant="category" text={categoryName(influencer.category)} />
          )}
        </div>
      </div>
      <div className="text-right flex flex-col gap-1">
        <div className="font-header font-bold text-2xl">
          {influencer.agency?.name}
        </div>
        <div className="text-defaultSecondary">
          {exclusivityName(influencer.exclusivity)}
        </div>
        <div className="text-defaultSecondary">{influencer.agentName}</div>
        {influencer.agentPhone && (
          <div className="text-defaultSecondary">{influencer.agentPhone}</div>
        )}
        {influencer.agentEmail && (
          <div className="flex justify-end">
            <CopyButton
              label={influencer.agentEmail}
              textToCopy={influencer.agentEmail}
              successMessage={`"${influencer.agentEmail}" copied to clipboard`}
            />
          </div>
        )}
      </div>
    </div>
  )
}
