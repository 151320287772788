import { SortDirectionEnum } from "~/__generated__/graphql"
import { cn } from "~/common/shadcn-utils"
import { Button } from "~/shadcn/ui/button"

import ArrowUpIcon from "~/images/icons/arrow-up.svg?react"
import ArrowDownIcon from "~/images/icons/arrow-down.svg?react"

export const SortableColumnHeader = ({
  onClick,
  sortDir,
  isActiveSort,
  children,
}: {
  onClick: () => void
  sortDir: SortDirectionEnum
  isActiveSort: boolean
  children: React.ReactNode
}) => {
  return (
    <Button
      onClick={onClick}
      variant="ghost"
      size="none"
      className={cn("font-semibold text-left justify-start", {
        "min-w-[60px]": isActiveSort,
      })}
    >
      <span>{children}</span>
      {isActiveSort && (
        <span className="ml-1">
          {sortDir === SortDirectionEnum.Asc ? (
            <ArrowDownIcon />
          ) : (
            <ArrowUpIcon />
          )}
        </span>
      )}
    </Button>
  )
}
