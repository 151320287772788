import { ApolloError } from "@apollo/client"

export const GraphqlError = ({ error }: { error: ApolloError }) => {
  return (
    <div className="overflow-auto flex-shrink-0">
      <div className="border-red-500 border rounded p-3 text-red-600 overflow-auto flex-shrink-0">
        <div>GraphQL Error</div>
        {error.graphQLErrors.map((e, i) => (
          <pre key={i}>{JSON.stringify(e, null, 2)}</pre>
        ))}
        {error.graphQLErrors.length === 0 ? (
          <pre>{JSON.stringify(error, null, 2)}</pre>
        ) : null}
      </div>
    </div>
  )
}
