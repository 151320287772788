import instagram from "~/images/icons/instagram"
import tiktok from "~/images/icons/tiktok"
import twitch from "~/images/icons/twitch"
import youtube from "~/images/icons/youtube"

export const platformLogoMap: Record<string, any> = {
  Instagram: instagram,
  TikTok: tiktok,
  YouTube: youtube,
  Twitch: twitch,
}
