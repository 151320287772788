import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "~/common/shadcn-utils"

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300",
  {
    variants: {
      variant: {
        default:
          "bg-defaultPrimary text-white hover:bg-hoveredPrimary disabled:bg-disabledPrimary rounded",
        destructive:
          "bg-red-500 text-slate-50 hover:bg-red-500/90 dark:bg-red-900 dark:text-slate-50 dark:hover:bg-red-900/90",
        outline: "bg-white text-gray90 border gray20 hover:bg-gray20 rounded",
        outlinePrimary:
          "bg-backgroundPrimary text-gray90 border border-defaultPrimary rounded",
        secondary:
          "bg-gray20 text-defaultSecondary hover:bg-gray20/60 dark:bg-slate-800 dark:text-slate-50 dark:hover:bg-slate-800/80 rounded",
        ghost:
          "hover:bg-gray20 hover:text-black dark:hover:bg-slate-800 dark:hover:text-slate-50 rounded",
        link: "text-blueDark underline-offset-4 hover:underline dark:text-slate-50",
        linkSecondary:
          "text-defaultSecondary underline underline-offset-4 hover:no-underline",
      },
      size: {
        default: "h-10 px-[24px] py-2",
        sm: "h-9 px-3",
        lg: "h-11 px-8",
        icon: "h-10 w-10",
        none: "",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button"
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    )
  }
)
Button.displayName = "Button"

export { Button, buttonVariants }
