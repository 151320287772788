import { cva, type VariantProps } from "class-variance-authority"
import { type ClassValue } from "clsx"
import React from "react"
import { cn } from "~/common/shadcn-utils"

export const H1 = ({
  className,
  children,
}: {
  className?: ClassValue
  children: React.ReactNode
}) => {
  return (
    <h1 className={cn("text-xl lg:text-3xl font-bold", className)}>
      {children}
    </h1>
  )
}

export const H2 = ({
  className,
  children,
}: {
  className?: ClassValue
  children: React.ReactNode
}) => {
  return <h2 className={cn("text-2xl font-bold", className)}>{children}</h2>
}

// https://www.figma.com/design/HPPMfQF9zbBNm5oHh69dtz/Zano-Webapp?node-id=506-80&t=JFsof33iept1q7no-4
export const textVariants = cva("", {
  variants: {
    variant: {
      default: "",
      body2: "text-body2 font-normal",
      body3: "text-body3",
      body4: "text-body4",
      header1: "text-header1 font-header font-bold",
      header2: "text-header2 font-header font-bold",
      header4: "text-header4 font-header font-bold",
    },
    wrapping: {
      default: "",
      wrap: "whitespace-pre-wrap break-words",
    },
  },
  defaultVariants: {
    variant: "default",
    wrapping: "default",
  },
})

export interface TypographyProps
  extends React.HTMLAttributes<HTMLHeadingElement>,
    VariantProps<typeof textVariants> {
  as?: React.ElementType
}

const Text = React.forwardRef<HTMLHeadingElement, TypographyProps>(
  ({ className, as: Comp = "span", variant, wrapping, ...props }, ref) => {
    return (
      <Comp
        className={cn(textVariants({ variant, wrapping, className }))}
        ref={ref}
        {...props}
      />
    )
  }
)
Text.displayName = "Text"

export default Text
