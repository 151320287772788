import onboardingImage from "~/images/onboarding.png"

export const SecondaryInfoSection = () => {
  return (
    <div className="flex-1 bg-backgroundPrimary flex flex-col justify-center items-center">
      <div className="max-w-xl py-4 px-2">
        <img
          src={onboardingImage}
          alt="View roster database, filter by categories"
        />
        <p className="font-header text-defaultPrimary font-bold text-3xl text-center mt-10">
          The platform for influencer agencies and managers
        </p>
      </div>
    </div>
  )
}
