import { gql } from "~/__generated__"

export const pricingFragment = gql(/* GraphQL */ `
  fragment DashboardScreen_pricing on Pricing {
    average
  }
`)

export const query = gql(/* GraphQL */ `
  query DashboardScreenQuery {
    currentUser {
      id
      firstName
      lastName
    }
    totals {
      agencies
      influencers
    }
    influencerStats {
      topCategories {
        category
        count
      }
      topCountries {
        country
        count
        countryFlagEmoji
      }
      topCreatorTypes {
        creatorType
        count
      }
      topPlatforms {
        platform
        count
      }
    }
    topAgencies {
      instagram {
        ...DashboardScreen_agency
      }
      tiktok {
        ...DashboardScreen_agency
      }
      twitch {
        ...DashboardScreen_agency
      }
      youtube {
        ...DashboardScreen_agency
      }
    }
    platformStats {
      tiktok {
        video {
          ...DashboardScreen_pricing
        }
        linkInBio {
          ...DashboardScreen_pricing
        }
      }
      instagram {
        reels {
          ...DashboardScreen_pricing
        }
        photo {
          ...DashboardScreen_pricing
        }
        story {
          ...DashboardScreen_pricing
        }
        linkInBio {
          ...DashboardScreen_pricing
        }
      }
      youtube {
        integration {
          ...DashboardScreen_pricing
        }
        dedication {
          ...DashboardScreen_pricing
        }
        shorts {
          ...DashboardScreen_pricing
        }
      }
      twitch {
        stream {
          ...DashboardScreen_pricing
        }
      }
    }
  }
`)

export const agencyFragment = gql(/* GraphQL */ `
  fragment DashboardScreen_agency on Agency {
    id
    name
    logoThumbnailUrl
    tiktokFollowers
    instagramFollowers
    youtubeSubscribers
    twitchSubscribers
  }
`)
