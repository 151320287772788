"use client"

import { Switch as HeadlessSwitch } from "@headlessui/react"
import { cva, VariantProps } from "class-variance-authority"

const switchOuterVariants = cva(
  "group relative inline-flex h-7 w-12 flex-shrink-0 cursor-pointer rounded-full border-2 border-gray30 bg-white transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2",
  {
    variants: {
      variant: {
        default: "data-[checked]:bg-backgroundPrimary",
        green: "data-[checked]:bg-greenLight",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
)

const switchInnerVariants = cva(
  "pointer-events-none inline-block h-6 w-6 transform rounded-full bg-gray50 shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5",
  {
    variants: {
      variant: {
        default: "group-data-[checked]:bg-defaultPrimary",
        green: "group-data-[checked]:bg-greenDark",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
)

export type SwitchProps = VariantProps<typeof switchOuterVariants> & {
  enabled: boolean
  setEnabled: (enabled: boolean) => void
}

export const Switch = ({ enabled, setEnabled, variant }: SwitchProps) => {
  return (
    <HeadlessSwitch
      checked={enabled}
      onChange={setEnabled}
      className={switchOuterVariants({ variant })}
    >
      <span className="sr-only">Use setting</span>
      <span aria-hidden="true" className={switchInnerVariants({ variant })} />
    </HeadlessSwitch>
  )
}
