import { useEffect } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { onboardingPersonalDetailsPath } from "~/common/paths"
import { useCurrentUserMaybe } from "../auth/CurrentUserContext"

export const RequireUserOnboarded = () => {
  const navigate = useNavigate()
  const { currentUser, result } = useCurrentUserMaybe()

  useEffect(() => {
    if (!result.loading && !currentUser?.isOnboarded && !currentUser?.isAdmin) {
      navigate(onboardingPersonalDetailsPath.pattern)
    }
  }, [currentUser, result.loading, navigate])

  if (result.loading) {
    return null
  }

  return <Outlet />
}
