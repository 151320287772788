import { Link, matchPath, useLocation } from "react-router-dom"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import {
  adminUsersPath,
  agenciesPath,
  dashboardPath,
  myRosterPath,
  rosterPath,
  settingsAccountPath,
} from "~/common/paths"
import { cn } from "~/common/shadcn-utils"
import { Hr } from "~/ui/Hr"

import AccountIcon from "~/images/icons/account.svg?react"
import AdminUsersIcon from "~/images/icons/admin-users.svg?react"
import AgenciesIcon from "~/images/icons/agencies.svg?react"
import DashboardIcon from "~/images/icons/dashboard.svg?react"
import MyRosterIcon from "~/images/icons/my-roster.svg?react"
import RosterIcon from "~/images/icons/roster.svg?react"
import ZanoLogo from "~/images/zano_logo_full.svg?react"

export const Sidebar = () => {
  const user = useCurrentUser()

  return (
    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4 border-r border-gray30">
      <div className="flex h-24 shrink-0 items-center">
        <ZanoLogo className="w-24 mx-auto" />
      </div>
      <nav className="flex flex-1 flex-col">
        <h3 className="text-gray50 mb-2 pl-3">MAIN MENU</h3>
        <ul className="flex flex-1 flex-col gap-y-2">
          <li>
            <ul className="-mx-2 space-y-1">
              <NavItem
                to={dashboardPath.pattern}
                label="Dashboard"
                icon={<DashboardIcon />}
              />
              <NavItem
                to={rosterPath.pattern}
                label="Roster Database"
                icon={<RosterIcon />}
              />
              <NavItem
                to={agenciesPath.pattern}
                label="Agencies"
                icon={<AgenciesIcon />}
              />
              <NavItem
                to={myRosterPath.pattern}
                label="My Roster"
                icon={<MyRosterIcon />}
              />
            </ul>
          </li>
          <li>
            <Hr />
          </li>
          <li>
            <h3 className="text-gray50 mb-2 pl-3">PERSONAL SETTINGS</h3>
            <ul className="-mx-2 space-y-1">
              <NavItem
                to={settingsAccountPath({})}
                label="Account"
                icon={<AccountIcon />}
              />
            </ul>
          </li>
          {user.isAdmin && (
            <>
              <li>
                <Hr />
              </li>
              <li>
                <h3 className="text-gray50 mb-2 pl-5">ADMIN MENU</h3>
                <ul className="-mx-2 space-y-1">
                  <NavItem
                    to={adminUsersPath.pattern}
                    label="Users"
                    icon={<AdminUsersIcon />}
                  />
                </ul>
              </li>
            </>
          )}
        </ul>
      </nav>
    </div>
  )
}

const NavItem = ({
  to,
  label,
  icon,
}: {
  to: string
  label: string
  icon: React.ReactNode
}) => {
  const location = useLocation()
  const selected = matchPath(location.pathname, to)

  return (
    <li>
      <Link
        to={to}
        className={cn({
          "flex items-center rounded w-full px-4 py-2 my-1 border-l-4 border-white text-hoveredSecondary hover:text-defaultPrimary hover:bg-backgroundPrimary hover:border-defaultPrimary":
            true,
          "text-defaultPrimary border-l-4 border-defaultPrimary bg-backgroundPrimary":
            selected,
        })}
      >
        <div className="w-6 mr-2 flex justify-center" aria-hidden="true">
          {icon}
        </div>
        <span>{label}</span>
      </Link>
    </li>
  )
}
