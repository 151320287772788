import { useQuery } from "@apollo/client"
import { useState } from "react"
import { UserSortFieldEnum, SortDirectionEnum } from "~/__generated__/graphql"
import { ADMIN_USERS_QUERY_DOCUMENT } from "./api"

const defaultSorts: Record<UserSortFieldEnum, SortDirectionEnum> = {
  [UserSortFieldEnum.Admin]: SortDirectionEnum.Asc,
  [UserSortFieldEnum.AgencyName]: SortDirectionEnum.Asc,
  [UserSortFieldEnum.Email]: SortDirectionEnum.Asc,
  [UserSortFieldEnum.InfluencerCount]: SortDirectionEnum.Desc,
  [UserSortFieldEnum.LastActivityAt]: SortDirectionEnum.Desc,
  [UserSortFieldEnum.LoginCount]: SortDirectionEnum.Desc,
  [UserSortFieldEnum.OnboardedAt]: SortDirectionEnum.Desc,
  [UserSortFieldEnum.UserName]: SortDirectionEnum.Asc,
}

const DEFAULT_SORT_FIELD = UserSortFieldEnum.Email

export const useUsers = () => {
  // const [searchValues, setSearchValues] = useState<SearchValues>({})
  const [sortField, setSortField] = useState<UserSortFieldEnum | undefined>(
    DEFAULT_SORT_FIELD
  )
  const [sortDir, setSortDir] = useState<SortDirectionEnum>(
    defaultSorts[DEFAULT_SORT_FIELD]
  )

  // Filtering by a query string uses its own ordering by default based on match quality,
  // so let's clear our custom one. Clicking a column again will reintroduce a custom order.
  // useEffect(() => {
  //   if (searchValues.query !== lastSearchField) {
  //     if (searchValues.query) {
  //       setSortField(undefined)
  //     } else {
  //       setSortField(DEFAULT_SORT_FIELD)
  //       setSortDir(defaultSorts[DEFAULT_SORT_FIELD])
  //     }
  //     setLastSearchField(searchValues.query)
  //   }
  // }, [lastSearchField, searchValues.query])

  const queryResult = useQuery(ADMIN_USERS_QUERY_DOCUMENT, {
    variables: {
      sortField: sortField,
      sortDirection: sortDir,
      first: 50,
      usersCursor: null,
    },
  })

  const users = queryResult.data?.users.edges.map((e) => e.node) ?? []

  const onSort = (field: UserSortFieldEnum) => {
    if (field === sortField) {
      setSortDir(
        sortDir === SortDirectionEnum.Asc
          ? SortDirectionEnum.Desc
          : SortDirectionEnum.Asc
      )
    } else {
      setSortField(field)
      setSortDir(defaultSorts[field])
    }
  }

  const onLoadNextPage = () => {
    queryResult.fetchMore({
      variables: {
        usersCursor: queryResult?.data?.users.pageInfo.endCursor,
      },
    })
  }

  return {
    ...queryResult,
    users,
    // setSearchValues,
    sortField,
    sortDir,
    onSort,
    onLoadNextPage,
    pageInfo: queryResult?.data?.users.pageInfo,
  }
}
