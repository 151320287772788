import { useState } from "react"
import { useFormContext, useWatch } from "react-hook-form"
import { categoryOptions } from "~/common/enums"
import { Button } from "~/shadcn/ui/button"
import { Checkbox } from "~/shadcn/ui/checkbox"
import { FormField, FormItem, FormMessage } from "~/shadcn/ui/form"
import { SearchLabel } from "./SearchLabel"
import { SearchValues } from "../../schema"
import { CategoryEnum } from "~/__generated__/graphql"

const FIELD_NAME = "categories"

export const CategoryFilter = () => {
  const form = useFormContext<SearchValues>()
  const [showAll, setShowAll] = useState(false)

  const selectedCategories =
    useWatch({
      control: form.control,
      name: FIELD_NAME,
    }) || []

  const toggleCategory = (category: CategoryEnum) => {
    if (selectedCategories.includes(category)) {
      form.setValue(
        FIELD_NAME,
        selectedCategories.filter((c) => c !== category)
      )
    } else {
      form.setValue(FIELD_NAME, [...selectedCategories, category])
    }
  }

  const selectAll = () => {
    form.setValue(
      FIELD_NAME,
      categoryOptions.map((c) => c.value)
    )
  }

  const clearAll = () => {
    form.setValue(FIELD_NAME, [])
  }

  return (
    <FormField
      control={form.control}
      name={FIELD_NAME}
      render={() => (
        <FormItem>
          <div className="flex flex-row justify-between">
            <SearchLabel text="Category" />
            <Button
              type="button"
              variant="linkSecondary"
              size="none"
              onClick={selectAll}
            >
              select all
            </Button>
          </div>
          <div className="grid grid-cols-4 gap-2">
            {categoryOptions
              .slice(0, showAll ? undefined : 20)
              .map((option) => (
                <CategoryOption
                  key={option.value}
                  label={option.label}
                  value={option.value}
                  selected={selectedCategories.includes(option.value)}
                  onClick={() => toggleCategory(option.value)}
                />
              ))}
          </div>
          <div className="flex justify-between mt-2">
            <Button
              type="button"
              variant="linkSecondary"
              size="none"
              onClick={() => setShowAll(!showAll)}
            >
              {showAll ? "see less" : "see more"}
            </Button>
            {selectedCategories.length > 0 && (
              <Button
                type="button"
                variant="linkSecondary"
                size="none"
                onClick={clearAll}
              >
                clear
              </Button>
            )}
          </div>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

const CategoryOption = ({
  label,
  value,
  selected,
  onClick,
}: {
  label: string
  value: CategoryEnum
  selected: boolean
  onClick: () => void
}) => {
  return (
    <div className="flex items-center space-x-2">
      <Checkbox
        id={`category-${value}`}
        checked={selected}
        onCheckedChange={onClick}
      />
      <label
        htmlFor={`category-${value}`}
        className="leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
      >
        {label}
      </label>
    </div>
  )
}
