import { useFormContext, useWatch } from "react-hook-form"
import { FormField, FormItem, FormMessage } from "~/shadcn/ui/form"
import { Checkbox } from "~/shadcn/ui/checkbox"
import { cn } from "~/common/shadcn-utils"
import { SearchValues } from "../../schema"

export const SocialMediaFilter = ({
  enabledFieldName,
  icon,
  title,
  children,
}: {
  enabledFieldName:
    | "tikTokEnabled"
    | "instagramEnabled"
    | "youtubeEnabled"
    | "twitchEnabled"
  icon: string
  title: string
  children: React.ReactNode
}) => {
  const form = useFormContext<SearchValues>()

  const enabled = useWatch({
    control: form.control,
    name: enabledFieldName,
  })

  return (
    <div className="flex flex-row gap-4 mt-4 mb-8">
      <div>
        <FormField
          control={form.control}
          name={enabledFieldName}
          render={({ field }) => (
            <FormItem>
              <Checkbox
                checked={field.value}
                onCheckedChange={(checked) => field.onChange(checked)}
              />
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      <div className={cn("flex-grow", { "opacity-40": !enabled })}>
        <div className="flex flex-row items-center gap-2">
          <div>
            <img src={icon} alt={title} className="w-[18px]" />
          </div>
          <span>{title}</span>
        </div>
        <div className="grid grid-cols-3 gap-4 mt-4">{children}</div>
      </div>
    </div>
  )
}
